<template>
  <div class="pag-solucoes pag-solucoes-hospitais">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
          <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão Hospitalar</li>
          <li><span>Apoio e Faturamento</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div id="abas" class="abas">
      <div class="abas-content">
        <ul>
          <li>
            <router-link to="/solucoes/hospitais/controle-assistencial/#n"
              >Controle Assistencial</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/hospitais/controle-administrativo/#n"
              >Controle Administrativo</router-link
            >
          </li>
          <li>
            <span>Apoio e Faturamento</span>
          </li>
          <li>
            <router-link to="/solucoes/hospitais/controle-de-suprimentos/#n"
              >Controle de Suprimentos</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="modulos">
      <div class="modulos-content">
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#gestaoContratuais"
            >
              <span class="container-img hospitais-gestao-contratuais"></span>
              <span>Gestão de Regras Contratuais – Faturamento</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#faturamentoParticulares"
            >
              <span
                class="container-img hospitais-faturamento-particulares"
              ></span>
              <span>Faturamento de Particulares</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a class="modulo-card" data-toggle="modal" data-target="#sms">
              <span class="container-img hospitais-sms"></span>
              <span>Sistema de Envio de SMS</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a class="modulo-card" data-toggle="modal" data-target="#sac">
              <span class="container-img hospitais-sac"></span>
              <span>SAC - Ouvidoria</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#painelAtendimento"
            >
              <span class="container-img hospitais-painel-atendimento"></span>
              <span>Painel de Atendimento</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#gestaoGlosas"
            >
              <span class="container-img hospitais-gestao-glosas"></span>
              <span>Gestão de Glosas</span>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#faturamentoConvenios"
            >
              <span
                class="container-img hospitais-faturamento-convenios"
              ></span>
              <span>Faturamento de Convênios</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#faturamentoApac"
            >
              <span class="container-img hospitais-faturamento-apac"></span>
              <span>Faturamento APAC</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#faturamentoAmbulatorial"
            >
              <span
                class="container-img hospitais-faturamento-ambulatorial"
              ></span>
              <span>Faturamento Ambulatorial - SUS</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#faturamentoAih"
            >
              <span class="container-img hospitais-faturamento-aih"></span>
              <span>Faturamento AIH - SUS</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <div class="modal fade" id="gestaoContratuais" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img modal-hospitais-gestao-contratuais
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Gestão de Regras Contratuais</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Controle de percentuais de incidência por clínica,
                        classificação (Mat/Med), técnica cirúrgica e diagnose
                        terapia (Ato);
                      </li>
                      <li>
                        Controle de cobrança automática de diária de
                        acompanhante de criança/idoso/paciente especiais;
                      </li>
                      <li>
                        Controle de cobrança automática de taxas de
                        registro/administrativas;
                      </li>
                      <li>
                        Controle de cobrança automática de taxas de recuperação
                        pós anestésica, enfermagem e mat.med;
                      </li>
                      <li>
                        Controle de cobrança automática de Taxa de Sala de
                        acordo com o porte anestésico do procedimento;
                      </li>
                      <li>
                        Controle de cobrança de percentuais de acréscimo para
                        horários especiais (após horário);
                      </li>
                      <li>
                        Controle de cobrança de pacotes (Composição do pacote);
                      </li>
                      <li>
                        Controle de débitos particulares baseado nos valores
                        pagos no controle de glosas e/ou no módulo Financeiro;
                      </li>
                      <li>
                        Controle dos valores de (Preço Máximo ao Consumidor) ou
                        (Preço da Fábrica do Balcão) do Brasíndice por
                        classificação e/ou por item (Item de uso restrito
                        hospitalar etc.);
                      </li>
                      <li>
                        Controle de faturamento por (Atividade Hospitalar);
                      </li>
                      <li>
                        Controle de geração automática de guias (Padrão TISS)
                        nas Recepções e Faturamento;
                      </li>
                      <li>
                        Controle de Faturamento no (Padrão TISS) guias
                        impressas, XML e <i>web services</i> (Em desenvolvimento
                        na versão V.17.01);
                      </li>
                      <li>
                        <i>Web Services</i> - Padrão TISS (Em desenvolvimento na
                        versão V.17.01);
                      </li>
                      <li>Controle de Entrega de Contas.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL -->

    <div class="modal fade" id="faturamentoParticulares" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img
                      modal-hospitais-faturamento-particulares
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Faturamento de Particulares</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Lançamento de todos os dados cadastrais para registro do
                        cliente na instituição, separando por tipo de
                        profissional e serviços;
                      </li>
                      <li>
                        Integração com os sistemas de Recepção e Financeiro;
                      </li>
                      <li>
                        Configuração de tabelas e Pacotes de Procedimentos
                        médicos e Hospitalares, estabelecendo valores a serem
                        cobrados, visando a agilidade e a confiabilidade na
                        cobrança do Cliente Particular;
                      </li>
                      <li>
                        Emissão da Nota Fiscal Eletrônica para o Paciente,
                        mediante os serviços prestados;
                      </li>
                      <li>
                        Impressão Analítica de todos os serviços prestados ao
                        cliente;
                      </li>
                      <li>
                        Opção de auditoria de toda a conta, visando à exatidão
                        dos dados, sejam eles de Honorários Médicos ou Recursos
                        complementares;
                      </li>
                      <li>
                        Serviços Hospitalares ou Materiais e Medicamentos;
                      </li>
                      <li>Apresentação e fechamento mensal de contas;</li>
                      <li>
                        Disponibilização de vários Modelos de recibos aos
                        Clientes;
                      </li>
                      <li>Inúmeros relatórios gerenciais disponíveis;</li>
                      <li>
                        Repasse Médico e de Prestador de Serviço, podendo
                        repassar percentuais sobre o valor faturado.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="sms" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span class="modal-container-img modal-hospitais-sms"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Envio de SMS</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Nosso sistema de SMS disponibiliza cadastro de cenários
                        dinâmicos de acordo com a necessidade do cliente tais
                        como: agendamento, liberação de resultados de exames,
                        sinais vitais do paciente ao médico assistente, datas
                        comemorativas, aniversariantes (pacientes, funcionários
                        etc.)
                      </li>
                      <li>Cadastro de mensagens padrões;</li>
                      <li>
                        Gestão de saldos de pacotes contratados por período;
                      </li>
                      <li>
                        Envio de SMS informativo para ao gestor quando ao
                        atingir o limite de mensagens contratadas;
                      </li>
                      <li>Envio SMS em massa;</li>
                      <li>Envio SMS individual;</li>
                      <li>Agendamento de envio de SMS;</li>
                      <li>Reenvio de SMS;</li>
                      <li>
                        Históricos de mensagens enviadas, pendentes e com falha
                        de envio com data e hora;
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="sac" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span class="modal-container-img modal-hospitais-sac"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>SAC - Ouvidoria</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>Cadastro de Pesquisa;</li>
                      <li>Cadastro de Questionários;</li>
                      <li>Cadastro de Questões;</li>
                      <li>Cadastro de Respostas;</li>
                      <li>Cadastro de Entrevistas;</li>
                      <li>Relatórios de análises.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="painelAtendimento" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img modal-hospitais-painel-atendimento
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Painel de Atendimento</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Filas organizadas, a fim de evitar aglomerações nas
                        unidades de atendimento;
                      </li>
                      <li>
                        Previsão de atendimento: com o número de senha impresso
                        e a visualização da última senha chamada no painel, o
                        paciente terá a estimativa de quando será atendido;
                      </li>
                      <li>
                        Informação e entretenimento para pacientes, com exibição
                        de vídeos institucionais ou TV aberta;
                      </li>
                      <li>
                        Agilidade no atendimento entre médico e paciente, com a
                        chamada no painel;
                      </li>
                      <li>
                        Informações necessárias para que o paciente chegue ao
                        seu destino estarão disponíveis de forma audiovisual;
                      </li>
                      <li>
                        Estatísticas: com a implantação do painel eletrônico os
                        gestores poderão acompanhar o desempenho do atendimento
                        ao paciente;
                      </li>
                      <li>Publicidade/Propaganda;</li>
                      <li>
                        Painéis de gestão: possibilita criar cenários com base
                        em indicadores de gestão. Esses indicadores serão
                        exibidos de forma estática e/ou dinâmica.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="gestaoGlosas" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-gestao-glosas"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Gestão de Glosas</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Faturamento de AIH integrado com as recepções e SADT em
                        interface amigável e multiusuário;
                      </li>
                      <li>
                        Consistência de compatibilidades de procedimentos;
                      </li>
                      <li>
                        Preparado para o SISRCA, na organização dos sistemas;
                      </li>
                      <li>
                        Processamento e concentração de todos os procedimentos
                        lançados em conta de acordo com as permissões avaliadas
                        periodicamente, baseado no diário oficial, garantindo
                        confiabilidade e segurança na geração de arquivos
                        eletrônicos para o governo;
                      </li>
                      <li>
                        Gerenciamento automático de permanência maior e diárias
                        de acompanhante;
                      </li>
                      <li>Controle da gratuidade, teto financeiro e SADT;</li>
                      <li>
                        Relatórios estatísticos e Indicadores por Especialidade,
                        Procedimentos, Médicos e Permanência.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="faturamentoConvenios" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img modal-hospitais-faturamento-convenios
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Faturamento de Convênios</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Flexibilidade das diversas regras de parametrização de
                        acordo com o contrato do convênio;
                      </li>
                      <li>
                        Flexibilidade de parametrização das regras de emissão de
                        faturas, por usuário;
                      </li>
                      <li>
                        Modelo de conta analítica que facilita análise das
                        contas por parte dos colaboradores do setor de
                        faturamento e auditores internos e externos;
                      </li>
                      <li>
                        Emissão de contas nos formulários próprios, por exemplo:
                        o TISS, IPSEMG, PMMG, IPASGO etc.;
                      </li>
                      <li>
                        Geração do faturamento em arquivo eletrônico XML como,
                        por exemplo, o padrão TISS;
                      </li>
                      <li>
                        Gerar relatórios demonstrativos para controle dos status
                        das contas, como: Não emitidas, Emitidas, Não Fechadas,
                        Fechadas, Apresentadas etc;
                      </li>
                      <li>
                        Relatórios demonstrativos para controle dos status das
                        contas por exemplo: Não emitidas, Emitidas, Não
                        Fechadas, Fechadas, Apresentadas, etc…
                      </li>
                      <li>Controle de fechamento mensal;</li>
                      <li>Rotina de auditoria de contas;</li>
                      <li>
                        Integração com os módulos de atendimento, controle de
                        estoque e administrativo-financeiro/contas a receber e
                        contabilidade;
                      </li>
                      <li>
                        Geração de vários relatórios, como: Faturamento Mensal,
                        Faturas Próprias, Demonstrativos de Conta Pacote,
                        Apuração de Remuneração, Comparativo de Despesas e
                        outros;
                      </li>
                      <li>
                        Gerenciamento do repasse de Diárias e Taxas, Honorários
                        Médicos, SADT, Materiais e Medicamentos e outros;
                      </li>
                      <li>
                        Parametrização de uso do sistema e definição do perfil
                        de acesso dos usuários;
                      </li>
                      <li>
                        Cadastro e manutenção da tabela de diárias e taxas de
                        acordo com a característica de cada convênio;
                      </li>
                      <li>
                        Cadastro e manutenção da tabela de Honorários Médicos de
                        acordo com as caraterísticas da tabela acordado em
                        contrato com a operadora ou convênio;
                      </li>
                      <li>
                        Cadastro dos Convênios com todas as especificações das
                        características do contrato estabelecido entre a
                        operadora e o hospital das regras de consistências e
                        cobranças e tabelas acordadas entre as partes;
                      </li>
                      <li>
                        Manutenção dos Lançamentos oriundos das internações e/ou
                        dos atendimentos efetuados na recepção;
                      </li>
                      <li>
                        Cadastro de Contas nos casos em que o paciente não foi
                        inserido no módulo de recepção;
                      </li>
                      <li>
                        Emissão de Contas em leiaute a ser definido e acordado
                        em contrato com a operadora, ou em relatório próprio, ou
                        no padrão da TISS;
                      </li>
                      <li>
                        Tratamento das irregularidades não previstas nos
                        contratos entre os convênios;
                      </li>
                      <li>Controle de Glosas;</li>
                      <li>Recursos de Glosas;</li>
                      <li>Controle dos Depósitos;</li>
                      <li>Emissão da Nota Fiscal;</li>
                      <li>Emissão de Recibo;</li>
                      <li>
                        Relatórios de Faturamento Mensal por: Especialidade, Procedimento e Convênio;
                      </li>
                      <li>Mapa de Faturamento;</li>
                      <li>Faturas – Serviços Hospitalares;</li>
                      <li>Faturas – Honorários Médicos;</li>
                      <li>Faturas – Recursos Complementares;</li>
                      <li>Faturamento por Centro de Custo;</li>
                      <li>
                        Análise de Contas Pacote, Por Conta, Por Procedimento;
                      </li>
                      <li>Relatório de Auditoria de Contas;</li>
                      <li>Controle de Repasses Médicos;</li>
                      <li>
                        : Faturamento Eletrônico: TISS, Abramge, IPSEMG, IAMSPE, IPASGO, Planserv, Polícia Militar.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="faturamentoApac" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-faturamento-apac"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Faturamento APAC</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Parametrização do sistema quanto à usabilidade perfil
                        dos usuários;
                      </li>
                      <li>Controle de Cotas/APACs;</li>
                      <li>
                        Manutenção dos lançamentos oriundo de atendimentos de
                        alta complexidade em regime ambulatorial;
                      </li>
                      <li>
                        Manutenção das APACs oriundas de preenchimento médico, atendendo à normatização e consistências previstas para cada tipo de procedimento;
                      </li>
                      <li>Transferência de APACs;</li>
                      <li>Emissão de Demonstrativos de evolução das APACs;</li>
                      <li>Emissão de Laudos;</li>
                      <li>
                        Geração de Arquivo para apresentação de acordo com
                        regras do SUS;
                      </li>
                      <li>Controle de Glosas;</li>
                      <li>Encerramento de APACs;</li>
                      <li>Relatórios gerenciais e de controles.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="faturamentoAmbulatorial" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img
                      modal-hospitais-faturamento-ambulatorial
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Faturamento Ambulatorial - SUS</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Controle de usuários para Fechamento e Apresentação de
                        Produção;
                      </li>
                      <li>
                        Gerenciamento do Instrumento de Registro de acordo com o
                        processamento; (Portaria SAS nº1362 de 04 de Dezembro de
                        2013);
                      </li>
                      <li>
                        Gerenciamento dos valores dos procedimentos de acordo com contratos ou metas estabelecidas com o Município/Estado/Governo (Para Repasse);
                      </li>
                      <li>Gerenciamento de Repasse no ato do lançamento;</li>
                      <li>
                        Consistência de Serviço/Classificação (CNES) por Unidade
                        de Atendimento da conta;
                      </li>
                      <li>
                        Controle de Cotas de acordo com a Ficha de Programação Orçamentária (FPO);
                      </li>
                      <li>
                        Controle de Procedimentos realizados X Procedimentos
                        Faturados (Contratualização);
                      </li>
                      <li>
                        Geração do Arquivo Faturamento Ambulatorial (BPA-C e
                        BPA-I) agrupado ou separado por Prestador de Serviços
                        (Arquivo de faturamento gerado por outro sistema);
                      </li>
                      <li>
                        Adequação das contas lançadas por meio da importação da tabela Unificada de acordo com o Processamento Vigente;
                      </li>
                      <li>
                        Fechar as contas após emissão; (Integridade da informação);
                      </li>
                      <li>
                        Bloquear manutenção das contas após a criação do BPA (Integridade da informação);
                      </li>
                      <li>
                        Integração do faturamento com outros módulos, como os de: Recepção, SADT e Diversos, por exemplo, facilitando o trabalho do faturamento quanto ao processamento das contas;
                      </li>
                      <li>
                        Gerenciamento de repasse de valores por profissional, exibidos por meio de relatórios, em razão de a tabela unificada não apresentar o valor do ambulatório com a divisão de SP e SH;
                      </li>
                      <li>
                        Relatório com consumo de mat/med, mesmo não sendo
                        faturado no SUS;
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="faturamentoAih" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-faturamento-aih"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Faturamento AIH - SUS</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Ambiente gráfico do sistema amigável para o usuário;
                      </li>
                      <li>
                        Regras de consistências e críticas dos procedimentos para orientar o usuário no processo da digitação das contas no dia;
                      </li>
                      <li>
                        Indicadores gerenciais existentes no sistema para atender às demandas de uma instituição;
                      </li>

                      <li>
                        Relatórios
                        <ul>
                          <li>
                            Serviços Profissionais > Honorários Médicos > Geral;
                          </li>
                          <li>
                            Serviços Profissionais > Produção dos Médicos >
                            Analítico;
                          </li>
                          <li>S.A.D.T > Analítico;</li>
                          <li>Relação de Pacientes > Por Procedimento;</li>
                          <li>Relação de Pacientes > Por Médico;</li>
                          <li>Relação de Pacientes >Reiteração;</li>
                          <li>Relação de Pacientes > Liberação Crítica;</li>
                          <li>Órteses e Próteses > Analítico;</li>
                          <li>Internação na UTI > Com Valor;</li>
                          <li>Internação na UTI > Mês a Mês;</li>
                          <li>
                            Procedimentos Especiais > Analítico > Modelo I;
                          </li>
                        </ul>
                      </li>
                      <li>
                        Estatísticas
                        <ul>
                          <li>AIHs Por Especialidade;</li>
                          <li>AIHs Por Sexo;</li>
                          <li>AIHs Por Faixa Etária;</li>
                        </ul>
                      </li>
                      <li>Facilidade na Criação do arquivo Sisa01;</li>
                      <li>
                        Parametrização do sistema quanto à usabilidade e perfil
                        dos usuários;
                      </li>
                      <li>
                        Manutenção dos lançamentos oriundos das internações do SUS efetuadas pelo sistema de Recepção a fim de validar as consistências necessárias a atender às normas estabelecidas;</li>
                      <li>
                        Emissão do Laudo de solicitação de AIH e ou suplementar;
                      </li>
                      <li>
                        Gerar arquivo para apresentação em conformidade com o SISAIH01;
                      </li>
                      <li>
                        Relatórios de Faturamento mensal, Resumo, por Procedimento e Centro de Custo;
                      </li>
                      <li>
                        Relatórios de Faturamento de S.A.D.T. Sintético e
                        analítico;
                      </li>
                      <li>Relatório de Honorários médicos;</li>
                      <li>
                        Faturamento de Órteses e próteses e Materiais e
                        procedimentos especiais;
                      </li>
                      <li>Pacientes com permanência a menor;</li>
                      <li>Permanências não pagas.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Cta />

    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
  methods: {
    Home() {
      window.location.href = "/Home";
    },
  },
};
</script>
