<template>
  <div class="pag-solucoes pag-solucoes-hospitais">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
          <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão Hospitalar</li>
          <li><span>Controle Assistencial</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div id="abas" class="abas">
      <div class="abas-content">
        <ul>
          <li>
            <span>Controle Assistencial</span>
          </li>
          <li>
            <router-link to="/solucoes/hospitais/controle-administrativo/#n"
              >Controle Administrativo</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/hospitais/apoio-e-faturamento/#n"
              >Apoio e Faturamento</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/hospitais/controle-de-suprimentos/#n"
              >Controle de Suprimentos</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="modulos">
      <div class="modulos-content">
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#pep"
            >
              <span class="container-img hospitais-pep"></span>
              <span>PEP – Prontuário Eletrônico do Paciente</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a class="modulo-card" data-toggle="modal" data-target="#pacs">
              <span class="container-img hospitais-pacs"></span>
              <span>PACS</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#blocoCirurgico"
            >
              <span class="container-img hospitais-bloco-cirurgico"></span>
              <span>Bloco Cirúrgico</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#radiologia"
            >
              <span class="container-img hospitais-radiologia"></span>
              <span>Sistema de Radiologia</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#endoscopia"
            >
              <span class="container-img hospitais-endoscopia"></span>
              <span>Sistema de Endoscopia</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#anatomiaPatologica"
            >
              <span class="container-img hospitais-anatomia-patologica"></span>
              <span>Sistema de Anatomia Patológica</span>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#ultrassonografia"
            >
              <span class="container-img hospitais-ultrassonografia"></span>
              <span>Sistema de Ultrassonografia</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#recepcaoInternacao"
            >
              <span class="container-img hospitais-recepcao-internacao"></span>
              <span>Recepção de Internação</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#tomografia"
            >
              <span class="container-img hospitais-tomografia"></span>
              <span>Sistema de Tomografia</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#hemodinamica"
            >
              <span class="container-img hospitais-hemodinamica"></span>
              <span>Sistema de Hemodinâmica</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#ressonanciaMagnetica"
            >
              <span
                class="container-img hospitais-ressonancia-magnetica"
              ></span>
              <span>Ressonância Magnética</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#higienizacaoLeitos"
            >
              <span class="container-img hospitais-higienizacao-leitos"></span>
              <span>Controle de Higienização de Leitos</span>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#controleAcesso"
            >
              <span class="container-img hospitais-controle-acesso"></span>
              <span>Controle de Acesso e Visitantes</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#agendamento"
            >
              <span class="container-img hospitais-sistema-agendamento"></span>
              <span>Sistema de Agendamento</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#hemoterapia"
            >
              <span class="container-img hospitais-hemoterapia"></span>
              <span>Controle de Hemoterapia</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#recepcaoAmbulatorial"
            >
              <span
                class="container-img hospitais-recepcao-ambulatorial"
              ></span>
              <span>Recepção Ambulatorial</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#laboratorio"
            >
              <span class="container-img hospitais-laboratorio"></span>
              <span>Sistema de Laboratório</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- ##### MODAL ##### -->

    <div id="pep" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span class="modal-container-img modal-hospitais-pep"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>PEP - Prontuário Eletrônico do Paciente</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Cadastro de Paciente;</li>
                      <li>Anamneses;</li>
                      <li>Prescrição Geral;</li>
                      <li>Pedidos de Antimicrobianos;</li>
                      <li>Evoluções;</li>
                      <li>Diagnósticos;</li>
                      <li>Atestados/Requerimentos;</li>
                      <li>Visualização de exames;</li>
                      <li>Acompanhamento de exames;</li>
                      <li>Receita médica;</li>
                      <li>Histórico do paciente;</li>
                      <li>Planejamento oncológico;</li>
                      <li>Checagem;</li>
                      <li>Balanço Hídrico;</li>
                      <li>Lista de pendências;</li>
                      <li>Relatórios;</li>
                      <li>Rotinas de trabalho.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="pacs" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span class="modal-container-img modal-hospitais-pacs"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>PACS</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Possibilidade de servidor local, em nuvem ou híbrido
                        (On-Primeses | SaaS);
                      </li>
                      <li>
                        Ambiente 100% web compatível com os principais
                        navegadores;
                      </li>
                      <li>App de visualização mobile;</li>
                      <li>Ambiente de laudo responsivo;</li>
                      <li>Laudo por Voz (Transcrição);</li>
                      <li>Worklist Dicom (Lista de trabalho);</li>
                      <li>
                        Interface customizável, possibilitando que seja aplicada
                        a identidade visual do cliente (logo e cores);
                      </li>
                      <li>
                        Acessos personalizados por perfil (Administrador,
                        Técnico, Radiologista, Solicitante, Paciente);
                      </li>
                      <li>
                        Ambiente com recursos centralizados, acesso ao laudo,
                        anamnese, anexar exames anteriores, solicitação de
                        exames, imagens, acesso ao histórico de laudos,
                        transmissão de exames dicom, envio de e-mail,
                        visualização de exames, download do exame, entre outros;
                      </li>
                      <li>
                        Personalização das colunas a serem exibidas na tela de
                        consulta exames;
                      </li>
                      <li>
                        Editor de textos customizado com recursos avançados como
                        identificador de quebra de página, espaçamento entre
                        linhas, pré-visualização do laudo, alinhamentos de
                        texto, inclusão de imagens, tabelas, entre outros;
                      </li>
                      <li>
                        Recurso para criação de matrizes de laudo (públicas ou
                        privadas), com utilização de atalhos;
                      </li>
                      <li>
                        Recurso para criação de frases padrões (públicas ou
                        privadas) com utilização de atalhos;
                      </li>
                      <li>
                        Revisor de laudos com recurso para edição, revisão e
                        liberação de forma otimizada, proporcionando ao
                        radiologista agilidade na revisão e liberação dos
                        laudos;
                      </li>
                      <li>Visualização de laudo e imagem na mesma tela;</li>
                      <li>Possibilidade de laudo de exames com anexo (PDF);</li>
                      <li>
                        Opção para anexar os pedidos médicos, exames anteriores
                        e/ou outras informações relevantes ao exame do paciente
                        (Áudio, Foto, PDF e DOC);
                      </li>
                      <li>Gravação de laudo em áudio;</li>
                      <li>Exportação de exames para outros hosts DICOM;</li>
                      <li>
                        Histórico de edição dos laudos, todas as alterações do
                        laudo são versionadas;
                      </li>
                      <li>
                        Acompanhamento de laudos por situação, em aberto,
                        analisar, revisar, liberar e liberado;
                      </li>
                      <li>
                        Identificação e destaque em tela de exames de urgência;
                      </li>
                      <li>Filtro de exames por radiologista;</li>
                      <li>
                        Notificação de exames liberados por e-mail, informando o
                        paciente que o exame está disponível para
                        visualização/impressão;
                      </li>
                      <li>
                        Compatibilidade com visualizadores no padrão DICOM.
                        Exemplo: Radiant, Weasis, Osirix, entre outros;
                      </li>
                      <li>
                        Visualizador com recursos de download das imagens (DICOM
                        ou JPEG);
                      </li>
                      <li>
                        Ambiente de produção replicado para homologação
                        automaticamente, possibilitando simulações com dados
                        reais, sem comprometer o ambiente de produção;
                      </li>
                      <li>Impressão sob demanda;</li>
                      <li>Multiempresa;</li>
                      <li>
                        Sistema de compressão automático (Sem perda de
                        qualidade).
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="blocoCirurgico" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-bloco-cirurgico"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Bloco Cirúrgico</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Parâmetros do sistema para definição de critérios de
                        acessos, forma de uso e campos necessários;
                      </li>
                      <li>Cadastro de Turnos;</li>
                      <li>Cadastro de Salas;</li>
                      <li>Patologias incorrentes;</li>
                      <li>Procedimentos invasivos;</li>
                      <li>Emissão da folha de sala;</li>
                      <li>
                        Faturamento dos procedimentos e lançamentos do bloco;
                      </li>
                      <li>Movimentação de estoques;</li>
                      <li>Cadastro de recém-nascido;</li>
                      <li>Solicitação de limpeza;</li>
                      <li>Agendamento de cirurgias;</li>
                      <li>
                        Relatórios de Cirurgias realizadas, Mapa cirúrgico;
                        Estatísticas; Dados de CCIH; controle de gastos etc.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="radiologia" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-radiologia"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Radiologia</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Integração com diversos módulos do sistema de gestão
                        hospitalar SGH (ERP);
                      </li>
                      <li>
                        Integração com clientes parceiros utilizando o método de
                        interfaceamento com diversas estruturas para
                        comunicação;
                      </li>
                      <li>
                        Integração com o PACS, permitindo, além da solicitação
                        do exame, a visualização de imagens de exames realizados
                        por diversos equipamentos;
                      </li>
                      <li>
                        Agendamento de pacientes com definição especifica quanto
                        à realização de exames;
                      </li>
                      <li>
                        Integração com clientes de operadoras para solicitação
                        de autorização de exames para convênios de forma
                        on-line;
                      </li>
                      <li>Controle de entrega de resultados;</li>
                      <li>Controle de liberação de laudos;</li>
                      <li>
                        Tela de lançamento com diversos parâmetros para otimizar
                        e agilizar o processo de registro de dados conforme a
                        necessidade de cada cliente;
                      </li>
                      <li>
                        Tela de lançamento com diversos parâmetros para otimizar
                        e agilizar o processo de registro de dados conforme a
                        necessidade de cada cliente;
                      </li>
                      <li>
                        Variedade de relatórios disponibilizados e prontos para
                        o uso permitindo que o usuário tenha o controle
                        necessário em diversos âmbitos para confirmar e dar
                        continuidade nos processos realizados;
                      </li>
                      <li>
                        Opção para gerenciamento de orçamento de exames para
                        controle conforme necessidade do cliente;
                      </li>
                      <li>
                        Recurso de envio de SMS, que uma vez
                        configurado/parametrizado no módulo de SMS, pode
                        controlar alertas específicos conforme necessidade do
                        cliente;
                      </li>
                      <li>
                        Ferramenta para criação de relatórios (Gerador de
                        relatórios) que pode ser utilizado pelo usuário, de
                        forma flexível e fácil para ser feito conforme as
                        necessidades do cliente;
                      </li>
                      <li>
                        Parametrização do sistema para definir características
                        usuais e perfil de usuário.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="endoscopia" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-endoscopia"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Endoscopia</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Integração com diversos módulos do sistema de gestão
                        hospitalar SGH (ERP);
                      </li>
                      <li>
                        Integração com clientes parceiros utilizando o método de
                        interfaceamento com diversas estruturas para
                        comunicação;
                      </li>
                      <li>
                        Integração com o PACS da SPDATA onde além da realização
                        da solicitação do exame, também é possível visualizar
                        imagens de exames realizados por diversos equipamentos;
                      </li>
                      <li>
                        Agendamento de pacientes com definição especifica quanto
                        a realização de exames;
                      </li>
                      <li>
                        Integração com clientes de operadoras para solicitação
                        de autorização de exames para convênios de forma online;
                      </li>
                      <li>Controle de entrega de resultados;</li>
                      <li>Controle de liberação de laudos;</li>
                      <li>
                        Tela de lançamento com diversos parâmetros para otimizar
                        e agilizar o processo de registro de dados conforme a
                        necessidade de cada cliente;
                      </li>
                      <li>
                        Opção de impressão de fichas, talões, relatórios e
                        etiquetas no ato do lançamento dos exames conforme a
                        necessidade;
                      </li>
                      <li>
                        Variedade de relatórios disponibilizados e prontos para
                        o uso que permite que o usuário tenha o controle
                        necessário em diversos âmbitos para confirmar e dar
                        continuidade nos processos realizados;
                      </li>
                      <li>
                        Opção para gerenciamento de orçamento de exames para
                        controle, conforme necessidade do cliente;
                      </li>
                      <li>
                        Recurso de envio de SMS que, uma vez
                        configurado/parametrizado no módulo de SMS, pode
                        controlar alertas específicos, conforme necessidade do
                        cliente;
                      </li>
                      <li>
                        Ferramenta para criação de relatórios (Gerador de
                        relatórios) que pode ser utilizada pelo usuário, de
                        forma flexível e fácil, para ser feito conforme as
                        necessidades do cliente;
                      </li>
                      <li>
                        Parametrização do sistema para definir características
                        usuais e perfil de usuário.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="anatomiaPatologica" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img modal-hospitais-anatomia-patologica
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Anatomia Patológica</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Integração com diversos módulos do sistema de gestão
                        hospitalar SGH (ERP);
                      </li>
                      <li>
                        Integração com clientes parceiros utilizando o método de
                        interfaceamento com diversas estruturas para
                        comunicação;
                      </li>
                      <li>
                        Integração com o PACS da SPDATA onde além da realização
                        da solicitação do exame, também é possível visualizar
                        imagens de exames realizados por diversos equipamentos;
                      </li>
                      <li>
                        Agendamento de pacientes com definição especifica quanto
                        a realização de exames;
                      </li>
                      <li>
                        Integração com clientes de operadoras para solicitação
                        de autorização de exames para convênios de forma online;
                      </li>
                      <li>Controle de entrega de resultados;</li>
                      <li>Controle de liberação de laudos;</li>
                      <li>
                        Tela de lançamento com diversos parâmetros para otimizar
                        e agilizar o processo de registro de dados conforme a
                        necessidade de cada cliente;
                      </li>
                      <li>
                        Opção de impressão de fichas, talões, relatórios e
                        etiquetas no ato do lançamento dos exames conforme a
                        necessidade;
                      </li>
                      <li>
                        Variedade de relatórios disponibilizados e prontos para
                        o uso que permite que o usuário tenha o controle
                        necessário em diversos âmbitos para confirmar e dar
                        continuidade nos processos realizados;
                      </li>
                      <li>
                        Opção para gerenciamento de orçamento de exames para
                        controle conforme necessidade do cliente;
                      </li>
                      <li>
                        Recurso de envio de SMS, que uma vez
                        configurado/parametrizado no módulo de SMS, pode
                        controlar alertas específicos conforme necessidade do
                        cliente;
                      </li>
                      <li>
                        Ferramenta para criação de relatórios (Gerador de
                        relatórios) que pode ser utilizado pelo usuário, de
                        forma flexível e fácil para ser feito conforme as
                        necessidades do cliente;
                      </li>
                      <li>
                        Parametrização do sistema para definir características
                        usuais e perfil de usuário.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="ultrassonografia" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-ultrassonografia"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Ultrassonografia</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Integração com diversos módulos do sistema de gestão
                        hospitalar SGH (ERP);
                      </li>
                      <li>
                        Integração com clientes parceiros utilizando o método de
                        interfaceamento com diversas estruturas para
                        comunicação;
                      </li>
                      <li>
                        Integração com o PACS da SPDATA onde além da realização
                        da solicitação do exame, também é possível visualizar
                        imagens de exames realizados por diversos equipamentos;
                      </li>
                      <li>
                        Agendamento de pacientes com definição especifica quanto
                        a realização de exames;
                      </li>
                      <li>
                        Integração com clientes de operadoras para solicitação
                        de autorização de exames para convênios de forma online;
                      </li>
                      <li>Controle de entrega de resultados;</li>
                      <li>Controle de liberação de laudos;</li>
                      <li>
                        Tela de lançamento com diversos parâmetros para otimizar
                        e agilizar o processo de registro de dados conforme a
                        necessidade de cada cliente;
                      </li>
                      <li>
                        Opção de impressão de fichas, talões, relatórios e
                        etiquetas no ato do lançamento dos exames conforme a
                        necessidade;
                      </li>
                      <li>
                        Variedade de relatórios disponibilizados e prontos para
                        o uso que permite que o usuário tenha o controle
                        necessário em diversos âmbitos para confirmar e dar
                        continuidade nos processos realizados;
                      </li>
                      <li>
                        Opção para gerenciamento de orçamento de exames para
                        controle conforme necessidade do cliente;
                      </li>
                      <li>
                        Recurso de envio de SMS, que uma vez
                        configurado/parametrizado no módulo de SMS, pode
                        controlar alertas específicos conforme necessidade do
                        cliente;
                      </li>
                      <li>
                        Ferramenta para criação de relatórios (Gerador de
                        relatórios) que pode ser utilizado pelo usuário, de
                        forma flexível e fácil para ser feito conforme as
                        necessidades do cliente;
                      </li>
                      <li>
                        Parametrização do sistema para definir características
                        usuais e perfil de usuário.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="recepcaoInternacao" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img modal-hospitais-recepcao-internacao
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Recepção de Internação</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Biometria (útil para controle de prontuários
                        duplicados);
                      </li>
                      <li>
                        Foto do paciente no cadastro (útil para controle de
                        prontuários duplicados/homônimos);
                      </li>
                      <li>Integração total com faturamento (TUSS/SUS);</li>
                      <li>
                        Controle de Acompanhantes por paciente (Útil para evitar
                        visitas não autorizadas pelo paciente ou família);
                      </li>
                      <li>
                        Criação de fichas e etiquetas conforme realidade do
                        cliente;
                      </li>
                      <li>
                        Exportação do CIHA; (Compatibilidade com o programa do
                        DATASUS);
                      </li>
                      <li>Controle de Prontuários (SAME);</li>
                      <li>Controle de Permanência e validade de guias;</li>
                      <li>Variedades de Relatórios Gerenciais;</li>
                      <li>
                        Emissão de itens não cobertos pela operadora no ato da
                        alta;
                      </li>
                      <li>
                        Dados da gestação e recém-nato (útil para controle do
                        livro de maternidade);
                      </li>
                      <li>
                        Confecção de fichas conforme necessidade do cliente;
                      </li>
                      <li>
                        Confecção de etiquetas conforme necessidade do cliente;
                      </li>
                      <li>
                        Confecção de relatórios gerenciais conforme necessidade
                        do cliente;
                      </li>
                      <li>
                        Parametrização do módulo conforme necessidade do
                        cliente;
                      </li>
                      <li>
                        Gerenciamento da Pré-internação minimizando o tempo da
                        internação do paciente;
                      </li>
                      <li>
                        Integração com o Painel de Atendimento organizando e
                        agilizando o atendimento do paciente;
                      </li>
                      <li>
                        Gerenciamento de fotos dos pacientes facilitando a
                        identificação do mesmo;
                      </li>
                      <li>
                        Gerenciamento por Biometria agilizando o atendimento e
                        evitando cadastro duplicados;
                      </li>
                      <li>Vasta gama de relatórios gerenciais para análise;</li>
                      <li>
                        Gerenciamento do C.I.H.A. evitando retrabalho no
                        programa do Datasus (C.I.H.A.);
                      </li>
                      <li>Fácil interação do colaborador com o Sistema SGH;</li>
                      <li>
                        A possibilidade de realizar uma pré-internação para
                        internações eletivas;
                      </li>
                      <li>
                        A possibilidade de lançar diagnóstico e anamnese para
                        clientes que não possuem o prontuário eletrônico;
                      </li>
                      <li>A opção de criar subconta;</li>
                      <li>
                        Poder cadastrar os acompanhantes no cadastro da
                        internação;
                      </li>
                      <li>Relatório de censo hospitalar completo;</li>
                      <li>Realizar o controle dos prontuários;</li>
                      <li>Controle de permanência e guias;</li>
                      <li>Realização do cadastro do recém-nato;</li>
                      <li>
                        A opção de emissão (reemissão) de fichas e etiquetas,
                        sem precisar entrar no cadastro da internação;
                      </li>
                      <li>
                        A possibilidade de utilizar o leitor biométrico,
                        evitando assim duplicidade de pacientes;
                      </li>
                      <li>
                        Parametrização do sistema para definir características
                        usuais e perfil de usuário;
                      </li>
                      <li>
                        Criação de fichas, documentos e etiquetas a serem
                        impressas no ato da internação;
                      </li>
                      <li>
                        Gerenciamento dos leitos, cadastro da estrutura de
                        leitos do hospital distribuídos por bloco, acomodação e
                        leitos, censo hospitalar, inventário;
                      </li>
                      <li>
                        Visualização do painel de leitos com posição de cada
                        leito;
                      </li>
                      <li>Pré-internação;</li>
                      <li>Transferências de localização;</li>
                      <li>Altas</li>
                      <li>Criação de contas, Controle de guias, protocolos;</li>
                      <li>Gestão e Controle dos prontuários;</li>
                      <li>
                        Emissão de fichas, Emissão de etiquetas (mala direta);
                      </li>
                      <li>
                        Relatórios gerencias como pacientes internados;
                        Pacientes transferidos; localização de pacientes; Altas;
                        Internações por convênio, especialidade, clinica;
                        Procedência de pacientes; Previsão de altas; Pacientes
                        pré-internados; Controle de permanências;
                      </li>
                      <li>Estatística hospitalar mensal e censo hospitalar;</li>
                      <li>Exportação do arquivo para C.I.H.A.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="tomografia" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-tomografia"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Tomografia</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Integração com diversos módulos do sistema de gestão
                        hospitalar SGH (ERP);
                      </li>
                      <li>
                        Integração com clientes parceiros utilizando o método de
                        interfaceamento com diversas estruturas para
                        comunicação;
                      </li>
                      <li>
                        Integração com o PACS da SPDATA onde além da realização
                        da solicitação do exame, também é possível visualizar
                        imagens de exames realizados por diversos equipamentos;
                      </li>
                      <li>
                        Agendamento de pacientes com definição especifica quanto
                        a realização de exames;
                      </li>
                      <li>
                        Integração com clientes de operadoras para solicitação
                        de autorização de exames para convênios de forma
                        on-line;
                      </li>
                      <li>Controle de entrega de resultados;</li>
                      <li>Controle de liberação de laudos;</li>
                      <li>
                        Tela de lançamento com diversos parâmetros para otimizar
                        e agilizar o processo de registro de dados conforme a
                        necessidade de cada cliente;
                      </li>
                      <li>
                        Opção de impressão de fichas, talões, relatórios e
                        etiquetas no ato do lançamento dos exames conforme a
                        necessidade;
                      </li>
                      <li>
                        Variedade de relatórios disponibilizados e prontos para
                        o uso que permite que o usuário tenha o controle
                        necessário em diversos âmbitos para confirmar e dar
                        continuidade nos processos realizados;
                      </li>
                      <li>
                        Opção para gerenciamento de orçamento de exames para
                        controle conforme necessidade do cliente;
                      </li>
                      <li>
                        Recurso de envio de SMS, que uma vez
                        configurado/parametrizado no módulo de SMS, pode
                        controlar alertas específicos conforme necessidade do
                        cliente;
                      </li>
                      <li>
                        Ferramenta para criação de relatórios (Gerador de
                        relatórios) que pode ser utilizado pelo usuário, de
                        forma flexível e fácil para ser feito conforme as
                        necessidades do cliente;
                      </li>
                      <li>
                        Parametrização do sistema para definir características
                        usuais e perfil de usuário.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="hemodinamica" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-hemodinamica"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Hemodinâmica</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Integração com diversos módulos do sistema de gestão
                        hospitalar SGH (ERP);
                      </li>
                      <li>
                        Integração com clientes parceiros utilizando o método de
                        interfaceamento com diversas estruturas para
                        comunicação;
                      </li>
                      <li>
                        Integração com o PACS da SPDATA onde além da realização
                        da solicitação do exame, também é possível visualizar
                        imagens de exames realizados por diversos equipamentos;
                      </li>
                      <li>
                        Agendamento de pacientes com definição especifica quanto
                        a realização de exames;
                      </li>
                      <li>
                        Integração com clientes de operadoras para solicitação
                        de autorização de exames para convênios de forma
                        on-line;
                      </li>
                      <li>Controle de entrega de resultados;</li>
                      <li>Controle de liberação de laudos;</li>
                      <li>
                        Tela de lançamento com diversos parâmetros para otimizar
                        e agilizar o processo de registro de dados conforme a
                        necessidade de cada cliente;
                      </li>
                      <li>
                        Opção de impressão de fichas, talões, relatórios e
                        etiquetas no ato do lançamento dos exames conforme a
                        necessidade;
                      </li>
                      <li>
                        Variedade de relatórios disponibilizados e prontos para
                        o uso que permite que o usuário tenha o controle
                        necessário em diversos âmbitos para confirmar e dar
                        continuidade nos processos realizados;
                      </li>
                      <li>
                        Opção para gerenciamento de orçamento de exames para
                        controle conforme necessidade do cliente;
                      </li>
                      <li>
                        Recurso de envio de SMS, que uma vez
                        configurado/parametrizado no módulo de SMS, pode
                        controlar alertas específicos conforme necessidade do
                        cliente;
                      </li>
                      <li>
                        Ferramenta para criação de relatórios (Gerador de
                        relatórios) que pode ser utilizado pelo usuário, de
                        forma flexível e fácil para ser feito conforme as
                        necessidades do cliente.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="ressonanciaMagnetica" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img modal-hospitais-ressonancia-magnetica
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Ressonância Magnética</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Integração com diversos módulos do sistema de gestão
                        hospitalar SGH (ERP);
                      </li>
                      <li>
                        Integração com clientes parceiros utilizando o método de
                        interfaceamento com diversas estruturas para
                        comunicação;
                      </li>
                      <li>
                        Integração com o PACS da SPDATA onde além da realização
                        da solicitação do exame, também é possível visualizar
                        imagens de exames realizados por diversos equipamentos;
                      </li>
                      <li>
                        Agendamento de pacientes com definição especifica quanto
                        a realização de exames;
                      </li>
                      <li>
                        Integração com clientes de operadoras para solicitação
                        de autorização de exames para convênios de forma
                        on-line;
                      </li>
                      <li>Controle de entrega de resultados;</li>
                      <li>Controle de liberação de laudos;</li>
                      <li>
                        Tela de lançamento com diversos parâmetros para otimizar
                        e agilizar o processo de registro de dados conforme a
                        necessidade de cada cliente;
                      </li>
                      <li>
                        Opção de impressão de fichas, talões, relatórios e
                        etiquetas no ato do lançamento dos exames conforme a
                        necessidade;
                      </li>
                      <li>
                        Variedade de relatórios disponibilizados e prontos para
                        o uso que permite que o usuário tenha o controle
                        necessário em diversos âmbitos para confirmar e dar
                        continuidade nos processos realizados;
                      </li>
                      <li>
                        Opção para gerenciamento de orçamento de exames para
                        controle conforme necessidade do cliente;
                      </li>
                      <li>
                        Recurso de envio de SMS, que uma vez
                        configurado/parametrizado no módulo de SMS, pode
                        controlar alertas específicos conforme necessidade do
                        cliente;
                      </li>
                      <li>
                        Ferramenta para criação de relatórios (Gerador de
                        relatórios) que pode ser utilizado pelo usuário, de
                        forma flexível e fácil para ser feito conforme as
                        necessidades do cliente.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="higienizacaoLeitos" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img modal-hospitais-higienizacao-leitos
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Controle de Higienização de Leitos</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Gestão de funcionários específicos do setor de limpeza;
                      </li>
                      <li>
                        Sistema de cores do painel de leitos definido pelo
                        Cliente;
                      </li>
                      <li>
                        Personalização de cada acomodação por “Tipo de
                        Criticidade” de limpeza;
                      </li>
                      <li>
                        Determinação dos materiais adequados para cada ambiente
                        hospitalar para cada tipo de limpeza;
                      </li>
                      <li>
                        Permite o gerenciamento de permissões de acesso por
                        usuário;
                      </li>
                      <li>Permite o controle do tempo de limpeza;</li>
                      <li>
                        Gestão das áreas a serem higienizadas vinculada à
                        acomodação na qual estão localizadas e consequentemente
                        o tipo de limpeza e de materiais adequados para uso;
                      </li>
                      <li>
                        Integrado à solução de suprimentos, é possível
                        requisitar os materiais necessários para limpeza
                        diretamente ao setor responsável;
                      </li>
                      <li>
                        Registro eletrônico do leito higienizado e posterior
                        emissão de relatórios gerenciais;
                      </li>
                      <li>
                        Monitoramento dos leitos a serem higienizados através de
                        painel. O alerta se inicia a partir da alta médica se
                        concretizando a partir da alta hospitalar onde é
                        registrada a saída do paciente do Hospital;
                      </li>
                      <li>
                        Gerenciamento de áreas: Critica, Semicrítica, não
                        critica;
                      </li>
                      <li>
                        Gerenciamento dos tipos de limpeza: Diária ou
                        concorrente; Terminal; Desinfecção concorrente;
                        Desinfecção terminal;
                      </li>
                      <li>
                        Painel de leitos interativos: permite ao usuário ativar
                        as principais ações diretamente pelo painel;
                      </li>
                      <li>
                        Painel de leitos com possibilidade de visualização por
                        bloco, acomodação, livres, ocupados, aguardando
                        higienização, em higienização, leitos livres, pacientes
                        com alta médica, inativos etc;
                      </li>
                      <li>
                        Painel de gerenciamento de limpezas, onde são
                        controlados os leitos aguardando para higienização, os
                        leitos em higienização. Neste painel também são
                        monitorados indicadores de tempo em que um leito se
                        encontra liberado para higienização;
                      </li>
                      <li>
                        Painel dos leitos que já se encontram higienizados com
                        controle de tempo gasto;
                      </li>
                      <li>
                        Gestão de chamados para limpeza de urgência por leito
                        com painel de monitoramento;
                      </li>
                      <li>Controle de <i>log</i> das principais operações;</li>
                      <li>Conceito – Tipos de Limpeza.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="controleAcesso" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-controle-acesso"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Controle de Acesso e Visitantes</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Parametrização conforme as regras de visitantes
                        instituídas pelo estabelecimento;
                      </li>
                      <li>
                        Gestão das portarias existentes no hospital e setores
                        aos quais elas dão acesso;
                      </li>
                      <li>
                        Gerenciamento dos setores a que uma portaria possibilita
                        acesso;
                      </li>
                      <li>
                        Controle de permissão de acesso para os funcionários,
                        profissionais, prestadores de serviço e/ou visitantes
                        aos setores do hospital;
                      </li>
                      <li>
                        Analisar compatibilidade de controle por catraca
                        eletrônica;
                      </li>
                      <li>
                        Parametrização dos campos a serem digitados para
                        identificação de um visitante;
                      </li>
                      <li>
                        Identificação de localização de funcionários e
                        profissionais dentro do hospital;
                      </li>
                      <li>Tipos de controles de identificação;</li>
                      <li>
                        Permite o gerenciamento de permissões de acesso por
                        usuário;
                      </li>
                      <li>Crachá – Gerados pelo Hospital ou em gráfica;</li>
                      <li>Etiquetas – geradas pelo gerador de relatórios;</li>
                      <li>Biometria – Ver equipamento compatível;</li>
                    </ul>
                    <h5>
                      Controle de locais onde um visitante pode ter acesso,
                      como:
                    </h5>
                    <ul>
                      <li>
                        Permite definir horário padrão de funcionamento por
                        setor;
                      </li>
                      <li>
                        Permite configurar as permissões de acesso ao local;
                      </li>
                      <li>
                        Controla o número de visitantes por acomodação e/ou por
                        leito;
                      </li>
                      <li>
                        Controla o número de acompanhantes por acomodação e/ou
                        por leito - Sistema de Agendamento.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="agendamento" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img modal-hospitais-sistema-agendamento
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Agendamento</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Flexibilidade para realizar criação e manutenção das
                        agendas;
                      </li>
                      <li>
                        Flexibilidade para realizar a parametrização das agendas
                        referente às informações de faixa etária, retorno,
                        observações etc;
                      </li>
                      <li>
                        Flexibilidade para realizar a parametrização das agendas
                        por municípios por exemplo: Belo Horizonte, Contagem e
                        Betim;
                      </li>
                      <li>
                        Flexibilidade para parametrizar as informações
                        necessárias na realização do agendamento;
                      </li>
                      <li>
                        Manuseio de forma simples por parte dos usuários para
                        realizar as rotinas de agendamento, desagendar,
                        reagendar e atendimento ao paciente;
                      </li>
                      <li>
                        Manuseio de forma simples por parte dos usuários para as
                        rotinas de visualizar a fila de espera, incluir e
                        excluir horário, gerenciar os dias indisponíveis,
                        visualizar os agendamentos do dia e de datas futuras,
                        pesquisar agendas por especialidades, pesquisar agenda
                        anual e atualizar horários agendados;
                      </li>
                      <li>
                        Manuseio de forma simples por parte dos usuários para
                        realizar a rotina de confirmação de que o paciente vai
                        comparecer na entidade para realizar o atendimento do
                        agendamento;
                      </li>
                      <li>
                        Envio de mensagem de forma automática para o paciente
                        informando o agendamento e solicitando que o mesmo
                        informe se vai comparecer na entidade para realizar
                        atendimento do agendamento;
                      </li>
                      <li>
                        Relatórios de pacientes agendados, horários cancelados,
                        pacientes atendidos por médico, convênio, especialidade,
                        controle de ações por usuário etc;
                      </li>
                      <li>
                        Estatísticas por especialidade, sexo, idade, horário,
                        convênio, dia da semana, tipo de agendamento e unidade;
                      </li>
                      <li>
                        Configurações e Parâmetros para definição de critérios
                        de acessos, usabilidade e permissões de usuários;
                      </li>
                      <li>Criação e manutenção de agendas;</li>
                      <li>Cadastro de dias indisponíveis e feriados;</li>
                      <li>Cadastro de tipos de agendamento;</li>
                      <li>Cadastro de situações do agendamento;</li>
                      <li>Cadastro de motivos;</li>
                      <li>Agendamento de pacientes;</li>
                      <li>Planejamentos do tratamento;</li>
                      <li>Pesquisa das agendas e pacientes agendados;</li>
                      <li>Manutenção dos protocolos;</li>
                      <li>Confirmar agendamento;</li>
                      <li>Horários livres;</li>
                      <li>Agenda de plantão.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="hemoterapia" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-hemoterapia"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Controle de Hemoterapia</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Parametrização do sistema para definir características
                        usuais e perfis dos usuários;
                      </li>
                      <li>
                        Cadastro de Doadores, Reações, Entrevistas, Técnicos,
                        Hemocomponentes, Exames para triagem, Condutas,
                        Intercorrências, Motivos de devolução/perdas e Locais de
                        armazenagem;
                      </li>
                      <li>Cadastro de Doadores;</li>
                      <li>Registro de Lançamentos de Doações;</li>
                      <li>Requisição de bolsas;</li>
                      <li>Transferência de bolsas;</li>
                      <li>Monitoramento da movimentação de bolsas;</li>
                      <li>Gerenciamento das Transfusões;</li>
                      <li>Agendamento – Encaminhamento ao Hemocentro.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="recepcaoAmbulatorial" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="
                      modal-container-img modal-hospitais-recepcao-ambulatorial
                    "
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Recepção Ambulatorial</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Controle dos pacientes em observação, por meio do
                        controle de encaminhamento e localização;
                      </li>
                      <li>
                        Opção de cadastro de diagnósticos, para clientes que não
                        possuem o prontuário e agendamento;
                      </li>
                      <li>
                        Grande quantidade de opção de relatórios disponíveis no
                        módulo;
                      </li>
                      <li>
                        Facilidade na unificação de prontuários duplicados;
                      </li>
                      <li>
                        Opções de busca no cadastro do paciente pelo nome, data
                        de nascimento, identidade, pelo nome da mãe, CNS, CPF e
                        por pesquisa parcial;
                      </li>
                      <li>
                        Possibilidade de, após finalizar um atendimento
                        particular, abrir a tela para emitir a conta, sem a
                        necessidade de sair do módulo; e abrir o módulo de
                        faturamento;
                      </li>
                      <li>
                        Integração do módulo com o SGO, possibilitando buscar o
                        beneficiário e gerar a guia, integrando com o módulo e o
                        faturamento;
                      </li>
                      <li>Realização do controle de prontuário;</li>
                      <li>
                        Possibilidade de gerenciar as fichas de atendimentos por
                        convênio, clínicas, unidade e especialidades;
                      </li>
                      <li>
                        Parametrização do sistema para definir características
                        usuais e perfis de usuário;
                      </li>
                      <li>
                        Criação de fichas, documentos e etiquetas a serem
                        impressas no atendimento;
                      </li>
                      <li>Parametrizações das usabilidades do agendamento;</li>
                      <li>Composição de receita médica e atestados;</li>
                      <li>Gerenciamento de Leitos de observação;</li>
                      <li>Emissão de etiquetas (mala direta) e fichas;</li>
                      <li>Lançamentos de faturamentos;</li>
                      <li>Criação de contas;</li>
                      <li>Agenda, Manutenção/criação;</li>
                      <li>Relação das ocorrências;</li>
                      <li>Controle das ações dos usuários;</li>
                      <li>
                        Dias indisponíveis; Horários cancelados, Reagendamento;
                      </li>
                      <li>Relatórios das agendas;</li>
                      <li>Gerenciamento de Plantão médico;</li>
                      <li>
                        Relatórios gerenciais de pacientes por: Convênio,
                        Especialidade, Clínica, Médico, Encaminhamento e Cidade;
                      </li>
                      <li>Exportação do arquivo de C.I.H.A;</li>
                      <li>Gestão do prontuário do paciente.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="laboratorio" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-laboratorio"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Laboratório</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Assinatura digital por meio de certificado ICP-Brasil;
                      </li>
                      <li>Liberação de Laudos Via <i>Web;</i></li>
                      <li>
                        Interfaceamento com equipamentos (processo de validação)
                        agilizando entrega de resultados;
                      </li>
                      <li>
                        Controle de estoque de matérias primas via módulo de
                        Gestão de Estoque;
                      </li>
                      <li>Autorização on-line conforme padrão TISS;</li>
                      <li>
                        Parametrização do sistema para definir características
                        usuais e perfis de usuários;
                      </li>
                      <li>Gerenciamento de insumos e contrastes;</li>
                      <li>Cadastro de técnicos;</li>
                      <li>Cadastros de Referências por idade/sexo;</li>
                      <li>Tipo de Equivalência;</li>
                      <li>Manutenção de kits de exames;</li>
                      <li>Cadastro de fórmulas;</li>
                      <li>Controle por Unidades de coleta;</li>
                      <li>Cadastro de Pacientes;</li>
                      <li>
                        Registro e controle dos atendimentos externos e das
                        internações;
                      </li>
                      <li>
                        Emissão de ficha de atendimento , talão, etiquetas de
                        identificação de tubos;
                      </li>
                      <li>
                        Emissão de resultados de acordo com a definição do
                        leiaute do laudo elaborado pelo usuário;
                      </li>
                      <li>
                        Visualização das solicitações de exames solicitados por
                        meio do PEP;
                      </li>
                      <li>Controle de entregas de resultados;</li>
                      <li>Agendamento de exames;</li>
                      <li>Orçamento de exames para pacientes particulares;</li>
                      <li>
                        Relatórios de exames realizados por: Especialidade,
                        Procedência, Paciente, Convênio, Médico, Técnico,
                        Procedimento, em laboratório; e Laudos emitidos;
                      </li>
                      <li>Mapa de produção laboratorial;</li>
                      <li>Movimento mensal;</li>
                      <li>Mapa estatístico;</li>
                      <li>Faturas para apresentação de faturamento;</li>
                      <li>Controle de Exames realizados X faturados;</li>
                      <li>Integração com faturamento;</li>
                      <li>
                        Interfaceamento com equipamentos para alimentação dos
                        dados de resultados de exames;
                      </li>
                      <li>
                        Integração com diversos laboratórios para compra de
                        serviços de terceiros (Inter-lis, ABC, Nefrodata,
                        LabLink, Hermes Pardini, Janus, Sérgio franco, Alvaro,
                        Matrix, Medicsul, Diagnóstico do Brasil);
                      </li>
                      <li>Disponibilidade de resultados para a <i>Web.</i></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Cta />
    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
  methods: {
    Home() {
      window.location.href = "/Home";
    },
  },
};
</script>
