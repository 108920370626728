<template>
  <div class="pag-solucoes pag-solucoes-hospitais">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
         <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão Hospitalar</li>
          <li><span>Controle de Suprimentos</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div id="abas" class="abas">
      <div class="abas-content">
        <ul>
          <li>
            <router-link to="/solucoes/hospitais/controle-assistencial/#n"
              >Controle Assistencial</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/hospitais/controle-administrativo/#n"
              >Controle Administrativo</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/hospitais/apoio-e-faturamento/#n"
              >Apoio e Faturamento</router-link
            >
          </li>
          <li>
            <span>Controle de Suprimentos</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="modulos">
      <div class="modulos-content">
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#almoxarifado"
            >
              <span class="container-img hospitais-sistema-almoxarifado"></span>
              <span>Sistema de Almoxarifado</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#controleFarmacia"
            >
              <span class="container-img hospitais-controle-farmacia"></span>
              <span>Controle de Estoque – Farmácia</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#gestaoCompra"
            >
              <span
                class="container-img hospitais-gestao-compra-suprimentos"
              ></span>
              <span>Gestão de Compras e Suprimentos</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <div class="modal fade" id="almoxarifado" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-sistema-almoxarifado"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Almoxarifado</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Parametrização do sistema quanto às usabilidades,
                        funcionalidades e perfil de usuário;
                      </li>
                      <li>
                        Cadastro dos itens de acordo com a definição de
                        nomenclatura podendo ser gerenciado por níveis
                        hierárquicos de grupos e subgrupo do item, referência e
                        classificação;
                      </li>
                      <li>Definição de limites por local;</li>
                      <li>
                        Emissão de etiquetas de identificação com códigos de
                        barras, dose unitária por horário, com nome comercial;
                      </li>
                      <li>Referência do código de barras com o fabricante;</li>
                      <li>Itens similares;</li>
                      <li>Diluições;</li>
                      <li>Locais de armazenagem;</li>
                      <li>Locais de entrega;</li>
                      <li>Vias de acessos;</li>
                      <li>
                        Cadastros de kits, Kits por item, kits rastreáveis;
                      </li>
                      <li>Emissão de Etiquetas por Kits;</li>
                      <li>Rastreabilidade;</li>
                      <li>Montagem de kit;</li>
                      <li>Desmembramento de Kit;</li>
                      <li>Unidades de Centos de Custos;</li>
                      <li>Motivos de inconsistência de requisição;</li>
                      <li>Inventário/Balanço;</li>
                      <li>Lançamento de contagens;</li>
                      <li>
                        Entradas de notas fiscais podendo buscar através do
                        pedido de compras;
                      </li>
                      <li>
                        Movimentação das Saídas para pacientes interno e externo, para o CDC e para funcionários;
                      </li>
                      <li>Controle de Itens Consignados;</li>
                      <li>
                        Movimentos de Devoluções para pacientes interno e externo, pacientes com CDC e para funcionários;
                      </li>
                      <li>Rastreabilidade Port. 344 ANVISA;</li>
                      <li>
                        Movimentação de Transferências, Reposição automatizada
                        através dos consumos para os locais;
                      </li>
                      <li>
                        Controle dos Antimicrobianos para Paciente
                        interno/externo;
                      </li>
                      <li>Controle dos Medicamentos excepcionais;</li>
                      <li>Gerenciamento das prescrições;</li>
                      <li>Dispensação por horário;</li>
                      <li>Manipulação de itens por capela e por grupo;</li>
                      <li>
                        Razão por itens analíticos, sintéticos, por referência e por grupo;
                      </li>
                      <li>
                        Medicamentos controlados (em atendimento à legislação);
                      </li>
                      <li>Curva ABC, XYZ;</li>
                      <li>Custo do período;</li>
                      <li>Consumo do período;</li>
                      <li>Custo médio unitário;</li>
                      <li>Controle de Validade de medicamentos;</li>
                      <li>Lucro líquido;</li>
                      <li>Ficha financeira;</li>
                      <li>Controle de consumo de antibióticos;</li>
                      <li>Comparativo de consumo;</li>
                      <li>Comparativo consumido/faturado.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="controleFarmacia" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-controle-farmacia"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Controle de Estoque – Farmácia</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>

                    <ul>
                      <li>
                        Parametrização do sistema quanto à usabilidade, a funcionalidades e perfil de usuário;
                      </li>
                      <li>
                        Cadastro dos itens de acordo com a definição de
                        nomenclatura podendo ser gerenciado por níveis
                        hierárquicos de Grupos e subgrupo do item, referência e
                        classificação;
                      </li>
                      <li>Definição de limites por local;</li>
                      <li>
                        Emissão de etiquetas de identificação com códigos de barras, dose unitária por horário e com nome comercial;
                      </li>
                      <li>Referência do código de barras com o fabricante;</li>
                      <li>Itens similares;</li>
                      <li>Diluições;</li>
                      <li>Locais de armazenagem;</li>
                      <li>Locais de entrega;</li>
                      <li>Vias de acessos;</li>
                      <li>
                        Cadastros de kits, Kits por item, kits rastreáveis;
                      </li>
                      <li>Emissão de Etiquetas por Kits;</li>
                      <li>Rastreabilidade;</li>
                      <li>Montagem de kit;</li>
                      <li>Desmembramento de Kit;</li>
                      <li>Unidades de Centos de Custos;</li>
                      <li>Motivos de inconsistência de requisição;</li>
                      <li>Inventário/Balanço;</li>
                      <li>Lançamento de contagens;</li>
                      <li>
                        Entradas de notas fiscais podendo buscar por meio do pedido de compras;
                      </li>
                      <li>
                        Movimentação das Saídas para paciente interno, para
                        paciente externo, para CDC e funcionários;
                      </li>
                      <li>Controle de Itens Consignados;</li>
                      <li>
                        Movimentos de Devoluções para pacientes interno e externo, com CDC e para funcionários;
                      </li>
                      <li>Rastreabilidade Port. 344 ANVISA;</li>
                      <li>
                        Movimentação das Saídas para pacientes interno e externo, com CDC e para funcionários;
                      </li>
                      <li>
                        Controle dos Antimicrobianos para Paciente
                        interno/externo;
                      </li>
                      <li>Controle dos Medicamentos excepcionais;</li>
                      <li>Gerenciamento das prescrições;</li>
                      <li>Dispensação por horário;</li>
                      <li>Manipulação de itens por capela e por grupo;</li>
                      <li>
                        Razão por itens analíticos, sintéticos, por referência e por grupo;
                      </li>
                      <li>
                        Medicamentos controlados (em atendimento à legislação);
                      </li>
                      <li>Curva ABC, XYZ;</li>
                      <li>Custo do período;</li>
                      <li>Consumo do período;</li>
                      <li>Custo médio unitário;</li>
                      <li>Controle de Validade de medicamentos;</li>
                      <li>Lucro líquido;</li>
                      <li>Ficha financeira;</li>
                      <li>Controle de consumo de antibióticos;</li>
                      <li>Comparativo de consumo;</li>
                      <li>Comparativo consumido/faturado.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="gestaoCompra" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-gestao-compra-suprimentos"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Gestão de Compras e Suprimentos</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais Funcionalidades</h5>
                    <ul>
                      <li>
                        Geração da necessidade de compra checando os estoques: mínimo, máximo e de segurança;
                      </li>
                      <li>
                        Envio do arquivo de cotação de forma automática para os
                        fornecedores, em formato XML;
                      </li>
                      <li>
                        SGHCot – Sistema de Cotação de Preços é destinado aos
                        fornecedores de insumos das instituições de saúde
                        usuárias do sistema
                      </li>
                      <li>
                        SGH-Gestão de Estoque, para lançamento de valores nos
                        processos de cotação e compra de produtos.
                      </li>
                      <li>
                        Importação do arquivo de retorno das cotações após
                        preenchimento realizado pelo fornecedor;
                      </li>
                      <li>
                        Definição dos vencedores após importação do arquivo de
                        retorno das cotações;
                      </li>
                      <li>
                        Flexibilidade para alterar por algum motivo o fornecedor
                        vencedor;
                      </li>
                      <li>
                        Fechamento do documento após definição de vencedores;
                      </li>
                      <li>
                        Envio automático, por e-mail, do pedido de compras para o fornecedor, em formato PDF;
                      </li>
                      <li>
                        Integração com os portais Bionexo, Pharmabuy e Apoio
                        Cotação;
                      </li>
                      <li>
                        Relatórios para gerenciamento: Compras por fornecedor, Estoque mínimo e máximo, Consumo médio mensal, trimestral, semestral e anual, Últimas compras etc.;
                      </li>
                      <li>
                        Integração do pedido de compras com a entrada da nota
                        fiscal;
                      </li>
                      <li>
                        Tela de cotação que permite visualização de todos os
                        itens e cotações enviadas pelos fornecedores com os
                        status: Itens definidos como pedido, itens definidos
                        como orçamento, itens comprados, itens não cotados por
                        nenhum fornecedor, realizar manutenção no valor e no
                        vencedor;
                      </li>
                      <li>Classificação de itens por fornecedor;</li>
                      <li>Gerenciamento de Estoque mínimo;</li>
                      <li>
                        Relatórios de Consumo médio Mensal, Trimestral,
                        Semestral, Anual, por paciente e CDC;
                      </li>
                      <li>Últimas compras;</li>
                      <li>Necessidade de compra;</li>
                      <li>Licitações;</li>
                      <li>Definição de vencedores;</li>
                      <li>Emissão de convites;</li>
                      <li>Emissão de licitações;</li>
                      <li>Edital classificatório;</li>
                      <li>Mapa de adjudicação;</li>
                      <li>Anexo de empenho;</li>
                      <li>Homologação;</li>
                      <li>Cotação de preços;</li>
                      <li>Relação de participantes;</li>
                      <li>Mapa de apuração;</li>
                      <li>Controle orçamentário;</li>
                      <li>Pedidos de Compras;</li>
                      <li>Definição de vencedores;</li>
                      <li>Mapa de cotação;</li>
                      <li>Relação de participantes;</li>
                      <li>
                        Integração com Portais de Compras (Apoio Cotações,
                        Bionexo);
                      </li>
                      <li>Informações Sped Fiscal;</li>
                      <li>Destinos de compra.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Cta />
    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
  methods: {
    Home() {
      window.location.href = "/Home";
    },
  },
};
</script>
