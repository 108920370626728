<template>
  <div class="pag-solucoes pag-solucoes-operadoras">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
         <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão de Operadoras</li>
          <li><span>Gestão da Operadora</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div id="abas" class="abas">
      <div class="abas-content-5">
        <ul>
          <li>
            <span>Gestão da Operadora</span>
          </li>
          <li>
            <router-link to="/solucoes/operadoras-de-planos/faturamento/#n"
              >Faturamento</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/operadoras-de-planos/controle-administrativo/#n"
              >Controle Administrativo</router-link
            >
          </li>
          <li>
            <router-link
              to="/solucoes/operadoras-de-planos/cartao-saude/#n"
              >Cartão Saúde</router-link
            >
          </li>
           <li>
            <router-link
              to="/solucoes/operadoras-de-planos/ans-agencia-nacional-de-saude/#n"
              >ANS</router-link
            >
          </li>
        </ul>
      </div>
    </div>

<!-- ##### MODULOS ##### -->
    <div class="modulos">
      <div class="modulos-content">
        <div class="row">

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#empresas-contratantes-beneficiarios"
            >
              <span class="container-img operadoras-empresas-contratantes-beneficiarios"></span>
              <span>Empresas contratantes / Beneficiários</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#comercial-e-vendas"
            >
              <span
                class="container-img operadoras-comercial-e-vendas"
              ></span>
              <span>Comercial e Vendas</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a class="modulo-card" data-toggle="modal" data-target="#produtos-planos">
              <span class="container-img operadoras-produtos-planos"></span>
              <span>Produtos / Planos</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a class="modulo-card" data-toggle="modal" data-target="#cobrancas">
              <span class="container-img operadoras-cobrancas"></span>
              <span>Cobranças</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#autorizacao-guias"
            >
              <span class="container-img operadoras-autorizacao-guias"></span>
              <span>Autorização de Guias</span>
            </a>
          </div>

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#portal-autorizador"
            >
              <span class="container-img operadoras-portal-autorizador"></span>
              <span>Portal Autorizador</span>
            </a>
          </div>
        </div>

        <div class="row">

          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#beneficiario-mobile"
            >
              <span
                class="container-img operadoras-beneficiaro-mobile"
              ></span>
              <span>Beneficiário Mobile</span>
            </a>
          </div>


          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#atendimento-cliente"
            >
              <span class="container-img operadoras-atendimento-cliente"></span>
              <span>Atendimento ao Cliente</span>
            </a>
          </div>

         

         
        </div>
      </div>
    </div>


 <!-- ##### MODAL ##### -->

    <div id="empresas-contratantes-beneficiarios" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span class="modal-container-img modal-operadoras-empresas-contratantes-beneficiarios"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Empresas contratantes / Beneficiário</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Gerenciamento da carteira da operadora, com inclusões, alterações, exclusões dentro da mesma tela, de forma intuitiva e dinâmica; </li>
                      <li>Ficha financeira desmembrada empresa/beneficiário e titular/dependentes;</li>
                      <li>Emissão de carteirinhas, conforme o layout preestabelecido pela operadora;</li>
                      <li>Extrato individualizado de pagamentos anuais, para fins de imposto de renda;</li>
                      <li>Declaração anual de quitação de débitos;</li>
                      <li>Controle de carência, conforme plano/produto do beneficiário ou customizado; </li>
                      <li>Controle de beneficiários não expostos e expostos beneficiários; </li>
                      <li>Cadastros de tipos de serviços, como transporte aeromédico, seguros e outros produtos oferecidos pela operadora; </li>
                      <li>Mudança de titularidade após exclusão contratual do beneficiário titular;</li>
                      <li>Cadastro individualizado de descontos/acréscimos aplicados à cobrança do beneficiário.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="comercial-e-vendas" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span class="modal-container-img modal-operadoras-comercial-e-vendas"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Comercial e Vendas</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                      Gestão de vendas com tratamento por equipe de venda ou
                      venda individualizada com definição de parcelas e percentuais; 
                      </li>
                      <li>
                      Cadastro de vendedores categorizados, conforme representante, vendedor de planos individuais/familiares, vendedor de planos coletivos, supervisores e gerentes;
                      </li>
                      <li>Cadastro de fontes de vendas para fornecer dados para gestão dos principais canais de prospecção de beneficiários; </li>
                      <li>
                      Integração dos pagamentos nos módulos RPA e Financeiro.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="produtos-planos" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-produtos-planos"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Produtos / Planos</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                      Gestão dos produtos/planos comercializados pela operadora, com parametrização inicial única;
                      </li>
                      <li>Inclusão das vigências de valores por faixas etárias previamente cadastradas
                      separadas por titulares, dependentes e agregados;</li>
                      <li>Criação de grupos e subgrupos de procedimentos para definição de cobertura do produto/plano;</li>
                      <li>Criação de exceções de procedimentos, possibilitando a operadora individualizar a cobertura,
                      quantidade limite e percentuais/valores de coparticipação;</li>
                      <li>Reajustes por faixas de planos e faixas etárias;</li>
                      <li>Cadastro de credenciados não habilitados no atendimento ao plano e/ou os procedimentos restritos a cada credenciado;</li>
                      <li>
                        Criação de faixas para descontos de dependentes nos contratos, conforme a quantidade de
                        beneficiários, possibilitando a operadora ofertar em suas campanhas para prospecção de contratos.
                      </li>   
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="cobrancas" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-cobrancas"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Cobranças</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                       Permite a operadora realizar suas cobranças para mais de
                       um banco com vários layouts já preestabelecidos.
                      </li>
                      <li>
                       O sistema permite várias formas de envio da cobrança: boletos e carnês impressos pelo próprio sistema, juntamente ao arquivo de registro; exportação bancária, conforme <i>layout</i> predefinido pelo banco; boletos pré-impressos e até mesmo carnês criados pela própria operadora;
                      </li>
                      <li>
                       Cálculo sobre análise de rescisão contratual, conforme RN 412
                       possibilitando a operadora realizar cobranças de coparticipações
                       ainda não faturadas, além de cobranças devidas sobre mensalidades
                       não pagas e ressarcimentos;
                      </li>
                      <li>
                       Baixa nos títulos por pagamento via arquivos eletrônicos bancários
                       e baixas de manuais com cálculo de juros e multa;
                      </li>
                      <li>
                       Envio automático de cobrança por e-mail;
                      </li>
                      <li>Definição de mensagens para impressão nos boletos/carnês.</li>
                      <li>Criação de cobranças avulsas, sem vínculo contratual, com
                      valores definidos pelo usuário;</li>
                      <li>
                       Criação de boletos de novas adesões, possibilitando a operadora
                       realizar a cobrança de mensalidade somente para as adesões realizadas
                       após a geração global do mês;
                      </li>
                      <li>
                        Emissão de mala direta para beneficiários inadimplentes com opção
                        de carta de cobrança emitida pelo próprio sistema;
                      </li>
                      <li>
                        O sistema se dispõe da rotina de acordos e parcelamentos de cobranças em atraso ou mesmo aquelas que ainda estão em dia, facilitando a negociação entre o setor de cobrança, beneficiários e empresas contratantes. Nela, podem ser definidos o valor e o vencimento da primeira parcela e o número de parcelas totais, bem como o intervalo de dias de vencimento entre as demais parcelas;
                      </li>
                      <li>
                        Integração com os módulos financeiro e contabilidade.
                      </li>        
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="autorizacao-guias" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-autorizacao-guias"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Autorização de Guias</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Emissão de autorizações já integradas ao módulo de faturamento, com todas as consistências necessárias para validação da autorização: carência, cobertura, quantidade contratual, inadimplência, DLP, retorno entre outros;
                      </li>
                      <li>
                        Emissão de guia nos padrões adotados pela TISS.
                      </li>
                      <li>
                        Possibilidade de seleção do prestador (clínica/hospital) de atendimento, 
                        o prestador executante e entidade de repasse do atendimento.
                      </li>
                      <li>
                        Geração de cobrança via balcão sobre os eventos coparticipativos.
                      </li>
                      <li>
                        Fornecimento direto de materiais e medicamentos para tratamentos em <i>home care</i>.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="portal-autorizador" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-portal-autorizador"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Portal Autorizador</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Login por prestador de serviços com controle de usuários diretamente no prestador; 
                      </li>
                      <li>
                        Interface amigável de fácil utilização; 
                      </li>
                      <li>
                        Verificação de elegibilidade por <i>web services</i> conforme padrões estabelecidos pela TISS reduzindo fraudes;
                      </li>
                      <li>
                        Controle de bases contratuais dos beneficiários e seus planos regidos na operadora reduzindo as glosas; 
                      </li>
                      <li>
                        Autorizações com emissão de protocolo e guias no padrão TISS; 
                      </li>
                      <li>Consulta de autorizações; </li>
                      <li>Geração de faturamento eletrônico com base no <i>web services </i>conforme padrões estabelecidos pela TISS agilizando o processo de faturamento, recebimentos e cobranças; </li>
                      <li>
                        Geração de arquivo de faturamento em XML como plano de contingência; 
                      </li>
                      <li>
                        Geração e controle dos protocolos de faturamento para acompanhamento da movimentação até o pagamento; 
                      </li>
                      <li>
                        Atendimento de autorizações geradas pela operadora ou em outros prestadores.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="beneficiario-mobile" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-beneficiaro-mobile"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Beneficiário Mobile</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Consulta dos dados cadastrais conforme RN 389/ANS; 
                      </li>
                      <li>
                        Consulta dos dados de utilização conforme RN 389/ANS; 
                      </li>
                      <li>
                        Consulta dos dados financeiros do contrato; 
                      </li>
                      <li>
                        Consulta autorizações pendentes; 
                      </li>
                      <li>
                        Consulta rede credenciada da operadora; 
                      </li>
                      <li>Realiza emissão de segunda via de cobrança; </li>
                      <li>Permite parametrizações de logomarca da operadora, valores mínimos de pagamento; </li>
                      <li>
                        Permite pagamento com cartão de crédito e débito; 
                      </li>
                      <li>
                        Permite acessar canais de comunicação com a operadora.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="atendimento-cliente" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-operadoras center">
                  <span
                    class="modal-container-img modal-operadoras-atendimento-cliente"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Atendimento ao cliente</h3>
                  <div class="modulo-descricao-texto descricao-operadoras">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                       Permite o cadastro dos atendimentos dos beneficiários de forma simples e ágil com emissão de protocolo no padrão estabelecido pela ANS;
                      </li>
                      <li>
                       Realiza a análise contratual para fins rescisórios, 
                       quando da solicitação de cancelamento de contrato integrado 
                       às rotinas de cobrança.
                      </li>  
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Cta />
    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
    methods: {
        Home() {
      window.location.href = '/Home'
    },
  }
};
</script>