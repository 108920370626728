<template>
  <div class="pag-solucoes pag-solucoes-hospitais">
    <section class="pag-title">
      <div class="container">
        <h2 class="title center">Soluções</h2>
      </div>
    </section>

    <div id="breadcrumb">
      <div class="container">
        <ul>
         <li>Home</li>
          <li>Soluções</li>
          <li>SPDATA Gestão Hospitalar</li>
          <li><span>Controle Administrativo</span></li>
        </ul>
      </div>
    </div>

    <MenuSolucoes />

    <div id="abas" class="abas">
      <div class="abas-content">
        <ul>
          <li>
            <router-link to="/solucoes/hospitais/controle-assistencial/#n"
              >Controle Assistencial</router-link
            >
          </li>
          <li>
            <span>Controle Administrativo</span>
          </li>
          <li>
            <router-link to="/solucoes/hospitais/apoio-e-faturamento/#n"
              >Apoio e Faturamento</router-link
            >
          </li>
          <li>
            <router-link to="/solucoes/hospitais/controle-de-suprimentos/#n"
              >Controle de Suprimentos</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="modulos">
      <div class="modulos-content">
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              href="#"
              class="modulo-card"
              data-toggle="modal"
              data-target="#bi"
            >
              <span class="container-img hospitais-sgh-intelligence"></span>
              <span>SGH Intelligence – Business Intelligence</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a class="modulo-card" data-toggle="modal" data-target="#sig">
              <span class="container-img hospitais-sig"></span>
              <span>SIG – Sistema de Informações Gerenciais</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#patrimonio"
            >
              <span class="container-img hospitais-patrimonio"></span>
              <span>Patrimônio</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#tesouraria"
            >
              <span class="container-img hospitais-tesouraria"></span>
              <span>Tesouraria</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#gestaoOrcamentaria"
            >
              <span class="container-img hospitais-gestao-orcamentaria"></span>
              <span>Gestão Orçamentária</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1200">
            <a class="modulo-card" data-toggle="modal" data-target="#rpa">
              <span class="container-img hospitais-rpa"></span>
              <span>GRT - Gestão de Repasses e Terceiros</span>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="200">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#pontoEletronico"
            >
              <span class="container-img hospitais-ponto-eletronico"></span>
              <span>Ponto Eletrônico</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="400">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#folhaPagamento"
            >
              <span class="container-img hospitais-folha-pagamento"></span>
              <span>Folha de Pagamento</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="600">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#gestaoFinanceira"
            >
              <span class="container-img hospitais-gestao-financeira"></span>
              <span>Gestão Financeira</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="800">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#gestaoCustos"
            >
              <span class="container-img hospitais-gestao-custos"></span>
              <span>Gestão de Custos</span>
            </a>
          </div>
          <div class="col col-1-6" data-aos="fade-up" data-aos-duration="1000">
            <a
              class="modulo-card"
              data-toggle="modal"
              data-target="#contabilidade"
            >
              <span class="container-img hospitais-contabilidade"></span>
              <span>Sistema de Contabilidade</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <div id="bi" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span class="modal-container-img modal-hospitais-bi"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>SGH Intelligence - Business Intelligence</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Aplicações e funções analíticas pré-construídas focadas
                        no contexto de hospitais, clínicas e planos de saúde;
                      </li>
                      <li>
                        Gerenciamento dos processos de extração, transformação, integração, validação e carga dos dados no Data <i>Warehouse</i> (ETL);
                      </li>
                      <li>Hipercubos OLAP;</li>
                      <li>
                        <i>Dashboards</i> (painéis executivos) compostos de gráficos, tabelas e indicadores;
                      </li>
                      <li>
                        Relatórios customizáveis, analíticos e sintéticos que
                        integram e relacionam dados de todos os processos;
                      </li>
                      <li>
                        Gestão dos indicadores, com definição de metas e
                        acompanhamento diário da execução dos mesmos;
                      </li>
                      <li>
                        Acesso via navegador <i>web</i>, possibilitando, inclusive, o acesso por dispositivos móveis;
                      </li>
                      <li>
                        Entrega automatizada de informações por e-mail e SMS;
                      </li>
                      <li>
                        A arquitetura tecnológica disponibilizada pelo SGH <i>Intelligence</i> suporta o desenvolvimento rápido de aplicações analíticas totalmente personalizadas e integradas tanto ao SGH quanto a outros sistemas de informação (ERP, CRM, SCM etc.) existentes em sua empresa, independente do fornecedor ou da plataforma;
                      </li>
                      <li>
                        Buscando reduzir o custo total de propriedade (TCO – Total <i>Cost Ownership</i>) da solução e entregar o que há de mais avançado no mercado de <i>Business Intelligence</i>, o SGH <i>Intelligenc</i> é parcialmente baseado em soluções livres, <i>softwares Open Source</i>, amplamente reconhecidas pelo mercado.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="sig" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span class="modal-container-img modal-hospitais-sig"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Informações Gerenciais</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Facilidade de acesso às informações gerenciais;</li>
                      <li>Cenários usuais pré-formatados;</li>
                      <li>
                        Acompanhamento em tempo real dos setores de produção;
                      </li>
                      <li>Acompanhamento por meio de relatórios e gráficos;</li>
                      <li>
                        Exportação e impressão dos dados em diversos formatos;
                      </li>
                      <li>Acompanhamento e autorização de compras;</li>
                      <li>
                        Acompanhamento on-line da situação financeira da
                        instituição;
                      </li>
                      <li>
                        Acesso facilitado às informações gerenciais do hospital;
                      </li>
                      <li>
                        Solução oferecida como cortesia ao adquirir as soluções SGH;
                      </li>
                      <li>
                        Capacitação gratuita aos gestores e administradores;
                      </li>
                      <li>Autorização eletrônica de documentos a pagar.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="patrimonio" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-patrimonio"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Patrimônio</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Possibilidade de depreciação por grupo de bem;</li>
                      <li>
                        Possibilidade de depreciação acelerada de itens
                        (depreciação individual)
                      </li>
                      <li>
                        Integração de entrada de notas fiscais como o controle
                        financeiro (contas a Pagar)
                      </li>
                      <li>
                        Integração de depreciações e baixas com o módulo
                        contabilidade;
                      </li>
                      <li>
                        Cadastro de bens com a possibilidade de inclusão de
                        fotos;
                      </li>
                      <li>
                        Controle de saída e retorno de um bem para manutenção;
                      </li>
                      <li>Cálculo de depreciação automatizado;</li>
                      <li>Controle de seguros e garantias (com alerta)</li>
                      <li>
                        Acompanhamento do histórico de toda a vida de um bem
                        dentro da empresa (compra, transferência, saídas e
                        retorno de manutenção e baixas);
                      </li>
                      <li>
                        Relatórios imprescindíveis para o controle, tais como:
                        termo de responsabilidade, mapa de depreciação,
                        inventário de bens, entre outros;
                      </li>
                      <li>
                        Cliente pode optar pela depreciação por grupo ou
                        individualmente;
                      </li>
                      <li>
                        Possibilita a identificação da origem do bem, se
                        próprio, doação etc.;
                      </li>
                      <li>Integração da depreciação com o módulo contábil;</li>
                      <li>
                        Controle das saídas e retornos dos bens para manutenção;
                      </li>
                      <li>
                        Identificar os bens depreciados com o valor inicial e a
                        depreciação acumulada (relatório de demonstrativos de
                        saldos);
                      </li>
                      <li>
                        Identificar a depreciação acumulada anterior ao período,
                        a depreciação sofrida no período e a acumulada;
                      </li>
                      <li>
                        Avisos de vencimento de seguro, manutenção e garantia;
                      </li>
                    </ul>
                    <h5>Outras funcionalidades</h5>
                    <ul>
                      <li>
                        Relatórios de inventário de bens analítico (que demonstra bem a bem) ou sintético (somente com o total referente ao grupo), que permite identificar o valor do bem, o percentual atribuído para depreciação, a depreciação do período e a depreciação acumulada. O modelo IV inclui também bens que não sofreram depreciação;
                      </li>
                      <li>
                        Integração com o módulo financeiro, gerando o contas a
                        pagar referente à aquisição do bem com controle de
                        parcelamento, mesmo que haja vários bens em uma mesma
                        nota;
                      </li>
                      <li>
                        Cadastro do bem permite a inclusão de imagens deste bem,
                        facilitando a identificação visual;
                      </li>
                      <li>
                        O sistema trabalha de forma integrada com os demais sistemas: Financeiro, Contabilidade e Custos;
                      </li>
                      <li>
                        Controle interno de transferência de Bens nos diversos
                        setores da empresa, para que se possa ter também a
                        localização do Bem dentro da Empresa;
                      </li>
                      <li>
                        Sistema com relatórios de fácil compreensão e com alto teor informativo;
                      </li>
                      <li>
                        Razão Analítico de Bens: este relatório exibe um detalhamento da situação cadastral do Bem;
                      </li>
                      <li>
                        Mapa de Depreciações, onde temos a evolução da
                        depreciação relativa ao Bem ou Bens;
                      </li>
                      <li>
                        Demonstrativo Saldo das Contas, onde teremos apenas os
                        valores dos Bens de modo simples e objetivo;
                      </li>
                      <li>
                        Demonstrativos da Depreciação Acumulada terão os valores
                        apenas da depreciação do período e a acumulada de forma
                        clara e objetiva;
                      </li>
                      <li>
                        Inventário de Bens, onde teremos o levantamento dos Bens
                        que fazem parte do ativo imobilizado da empresa com
                        todas as informações relativas ao mesmo;
                      </li>
                      <li>
                        Relatório de Manutenção: neste relatório é exibido toda a movimentação de entradas e saídas dos Bens, apresentando ainda valores de orçamentos e valores efetivamente pagos;
                      </li>
                      <li>
                        Listagem de Bens para Controle de Seguro. Este relatório apresenta uma listagem completa dos bens segurados e a seguradora responsável pelo seguro;
                      </li>
                      <li>
                        Ficha de Bens, onde teremos uma ficha cadastral do bem;
                      </li>
                      <li>
                        Relatório de Localizações: com este relatório é possível saber onde está localizado um bem dentro da empresa.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="tesouraria" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-tesouraria"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Tesouraria</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Integração com o modulo de recepção e faturamento;
                      </li>
                      <li>
                        Possibilidade de criação de caixas individuais para cada
                        recepção ou para cada funcionário, permitindo um
                        controle de lançamentos e baixas;
                      </li>
                      <li>
                        Análise de saldos diários (controle de fluxo de caixa);
                      </li>
                      <li>
                        Controle de receitas, tais como aluguéis, refeição de acompanhantes, entre outros;
                      </li>
                      <li>
                        Relatórios personalizados (analíticos ou sintéticos);
                      </li>
                      <li>Possibilidade de emissão de recibos;</li>
                      <li>Apuração de despesas ou receitas por CDC;</li>
                      <li>Relatório de auditoria do sistema;</li>
                      <li>
                        Possibilidade de baixa por documento com descontos ou
                        acréscimos;
                      </li>
                      <li>
                        Possibilidade de baixa por paciente (baixa de diversos
                        documentos de uma só vez).
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="gestaoOrcamentaria" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-gestao-orcamentaria"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Gestão Orçamentária</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Sistema para Gestão Orçamentária e projeção de fluxo de
                        caixa;
                      </li>
                      <li>Acompanhamento mensal orçado / realizado;</li>
                      <li>
                        Comparativo do orçamento com recebimento (faturamento);
                      </li>
                      <li>Controle de orçamento por grupos de despesas;</li>
                      <li>
                        Previsão de recebimentos, pagamentos para elaboração de
                        fluxo de caixa;
                      </li>
                      <li>Consolidação anual, orçado / realizado;</li>
                      <li>Comparativo do orçamento / fluxo de caixa;</li>
                      <li>Demonstrativo do orçamento projetado / realizado.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="rpa" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span class="modal-container-img modal-hospitais-rpa"></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>GRT - Gestão de Repasses e Terceiros</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        <strong>Aprimoramento do Planejamento Financeiro</strong> <br>
                        Nossa solução oferece a notável vantagem de proporcionar um fluxo de caixa completo, mesmo antes da emissão das notas fiscais pelos prestadores de serviços. Isso se traduz em uma visão precisa e em tempo real das receitas e despesas, permitindo um planejamento financeiro mais eficiente e uma projeção de resultados mais precisa. Essa capacidade contribui significativamente para uma gestão financeira mais eficaz e para a tomada de decisões estratégicas embasadas em dados concretos.
                      </li>
                      <li>
                        <strong>Automação e Eficiência no Gerenciamento de Repasses</strong> <br>
                        Nossa solução proporciona uma gestão altamente eficiente dos repasses financeiros, otimizando o gerenciamento dos provisionamentos a serem pagos por serviços prestados por terceiros na área da saúde. A integração com os módulos de faturamento elimina retrabalhos e erros, fornecendo uma visão completa e sempre atualizada dos lançamentos relacionados aos profissionais de saúde prestadores de serviços. A funcionalidade de mala direta, por sua vez, oferece uma visão completa e transparente dos repasses aos profissionais médicos, aprimorando a gestão e agilizando o processo de pagamento. Isso resulta na satisfação e confiança contínuas dos profissionais de saúde que colaboram com sua instituição.
                      </li>
                      <li>
                        <strong>Conformidade Regulatória e Conciliação de Dados</strong> <br>
                        Entendemos a importância do estrito cumprimento das obrigações regulatórias, e nossa solução vai além do mero envio de informações para a EFD-Reinf. O painel de conciliação específico para a família R-4000, um recurso diferencial da nossa solução "EFD-Reinf," permite que os contadores conciliem minuciosamente todos os lançamentos antes do envio. Isso garante a precisão e conformidade das informações, mitigando potenciais erros e contribuindo para o cumprimento rigoroso dos requisitos regulatórios.
                        <br>
                        Além disso, nossa solução gera automaticamente as pendências de envio dos eventos R-4010 e R-4020 após a conciliação dos dados. Isso assegura que as informações estejam prontas e livres de atrasos e inconsistências no momento do envio à EFD-Reinf.
                      </li>
                      <li>
                        <strong>Preservação da Integridade dos Dados</strong> <br>
                        A integridade dos dados é uma prioridade fundamental em nosso sistema. Portanto, nosso sistema é projetado para evitar alterações em informações de parcelas ou documentos que já tenham sido conciliados. Isso assegura que os dados enviados à EFD-Reinf sejam mantidos em sua forma original, confiáveis e em total conformidade com os requisitos regulatórios.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="pontoEletronico" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-ponto-eletronico"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Ponto Eletrônico</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Apuração automática de faltas, atrasos, horas-extras,
                        adicional noturno;
                      </li>
                      <li>Banco de Horas;</li>
                      <li>Controle Automático de Escala;</li>
                      <li>
                        Controle de trabalho externo e impressão da ficha;
                      </li>
                      <li>Impressão de Crachás com ou sem código de barras;</li>
                      <li>
                        Marcação:
                        <ul>
                          <li>por leitura de scanner;</li>
                          <li>por digitação no teclado;</li>
                          <li>por integração com relógio eletrônico;</li>
                          <li>por leitor biométrico;</li>
                        </ul>
                      </li>
                      <li>
                        Controle de horário de estagiários, residentes,
                        terceiros e visitantes;
                      </li>
                      <li>Controle de múltiplos horários por funcionário;</li>
                      <li>Integração com o sistema de Departamento Pessoal;</li>
                      <li>Visualização e impressão de gráficos;</li>
                      <li>Controle de históricos de horários;</li>
                      <li>Tolerância para atrasos, horas-extras;</li>
                      <li>Controle de feriados por empresa;</li>
                      <li>Movimentação por período;</li>
                      <li>
                        Proteção contra alteração de horários por usuário no
                        computador.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="folhaPagamento" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-folha-pagamento"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Folha de Pagamento</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Cálculo da folha de pagamento (semanal, quinzenal,
                        mensal);
                      </li>
                      <li>Cálculo do adiantamento salarial;</li>
                      <li>Cálculo de rescisão:</li>
                      <li>Cálculo de férias;</li>
                      <li>Cálculo de 13º salário (1ª e 2ª parcela);</li>
                      <li>PPP – Perfil Profissiográfico Previdenciário;</li>
                      <li>
                        Controle eletrônico do cartão ponto (todos os modelos).
                      </li>
                    </ul>

                    <h5>
                      As principais rotinas e procedimentos são contemplados em
                      submódulos, como:
                    </h5>
                    <ul>
                      <li>Admissão;</li>
                      <li>Jornada de trabalho;</li>
                      <li>Férias, incluindo o direito às férias proporcionais ao empregado que pede demissão com menos de um ano de trabalho;</li>
                      <li>Salário e remuneração;</li>
                      <li>Rescisão do contrato de trabalho.</li>
                    </ul>
                    <h5>Contemplando:</h5>
                    <ul>
                      <li>Impressão de contracheques;</li>
                      <li>Resumo da folha de pagamento;</li>
                      <li>Rescisão;</li>
                      <li>Guia do seguro desemprego;</li>
                      <li>Contrato de experiência;</li>
                      <li>Aviso de férias;</li>
                      <li>Férias;</li>
                      <li>Relatórios de conferências e Arquivo Magnético;</li>
                      <li>Guia de GPS;</li>
                      <li>Seguro desemprego;</li>
                      <li>
                        Informe de rendimentos para o IRPF individualizado por
                        funcionário;
                      </li>
                      <li>
                        Impressão de controles de ponto, como:
                        <ul>
                          <li>Quadro de horários;</li>
                          <li>Escala de revezamento;</li>
                          <li>
                            Folha de ponto para reconhecimento da pontualidade.
                          </li>
                        </ul>
                      </li>
                    </ul>

                    <h5>Relatórios complementares:</h5>
                    <ul>
                      <li>
                        Advertências disciplinares, registrando no histórico do
                        funcionário suas ocorrências;
                      </li>
                      <li>
                        Programação de suspensão automática, de acordo com
                        determinado número de advertências;
                      </li>
                      <li>Suspensão disciplinar;</li>
                      <li>
                        Emissão de CAT – Comunicado de Acidente de Trabalho;
                      </li>
                      <li>Recibo de CTPS;</li>
                      <li>
                        Relação de funcionários para compra de vales-transportes;
                      </li>
                      <li>Relação de entrega do vale-transporte;</li>
                      <li>
                        Declaração de não beneficiário do vale transporte;
                      </li>
                      <li>
                        GRCS – Guia de Recolhimento da Contribuição Sindical impressa pelo sistema de acordo com o sindicato que o funcionário for enquadrado;
                      </li>
                      <li>Geração de Arquivo Magnético da CAGED.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="gestaoFinanceira" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-gestao-financeira"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Gestão Financeira</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Personalização das entradas de dados conforme parâmetros
                        pré-definidos;
                      </li>
                      <li>Possibilidade de gerenciar várias empresas;</li>
                      <li>
                        Cadastro de clientes, fornecedores, bancos, usuários,
                        plano de contas etc.;
                      </li>
                      <li>
                        Lançamentos de contas a pagar, contas a receber – pacientes, convênios e/ou prestadores de serviços;
                      </li>
                      <li>
                        Pagamento com todas as deduções de impostos – ISS, INSS,
                        IR, PIS, COFINS;
                      </li>
                      <li>Fechamento de contas de pacientes;</li>
                      <li>Controle do parcelamento das contas de pacientes;</li>
                      <li>Conta-corrente por convênio</li>
                      <li>Conta-corrente por médico</li>
                      <li>Movimentação bancária;</li>
                      <li>
                        Conciliação bancária – Permite trabalhar com saldo real
                        ou provisionado;
                      </li>
                      <li>Resumo da movimentação bancária;</li>
                      <li>Emissão e controle de cheques.</li>
                    </ul>
                    <h5>Relatórios Gerenciais</h5>
                    <ul>
                      <li>
                        Contas a receber por: tipo de fornecedor, fornecedor e vencimento; de pacientes e de convênios;
                      </li>
                      <li>Cheques emitidos, pendentes ou ambos;</li>
                      <li>
                        Contas a pagar – contas pagas, pendentes ou ambas;
                      </li>
                      <li>Extratos bancários.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="gestaoCustos" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-gestao-custos"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Gestão de Custos</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>Custeio por Centros de Custos;</li>
                      <li>
                        Apropriação dos custos de Centros de Custos auxiliares
                        pelo método de absorção recíproco;
                      </li>
                      <li>
                        Integração e apropriação dos custos diretamente para os
                        centros de custos pelo apontamento do critério de rateio
                        da conta;
                      </li>
                      <li>
                        Acompanhamento em tempo real da composição dos custos
                        diretos e comparativo com orçado;
                      </li>
                      <li>
                        Consolidação da produção das áreas de apoio para
                        parâmetros de critério dos rateios dos Centros de Custos
                        auxiliares;
                      </li>
                      <li>
                        Processamento do rateio e transferência dos custos
                        sempre que necessários e/ou mudança de parâmetros
                        (critérios de rateio);
                      </li>
                      <li>
                        Análise da composição dos itens de custos de cada Centro
                        de Custo de forma analítica tendo como base a origem do documento gerado;
                      </li>
                      <li>
                        Análise da composição dos indicadores de custos como
                        paciente/dia, leito/dia, custo da refeição, metro
                        quadrado de limpeza etc.;
                      </li>
                      <li>
                        Análise da variação mensal dos custos por Centros de
                        Custos, Itens de custos e Indicadores de custos;
                      </li>
                      <li>
                        Composição dos custos diretos e indiretos por
                        procedimentos e por tipo de Convênios;
                      </li>
                      <li>
                        Relatórios de comparativos dos custos por Centros de
                        custos, Itens de custos e Indicadores de custos com
                        meses anteriores;
                      </li>
                      <li>
                        Relatórios do Custo Total para o Centro de Custo quanto aos Custos Diretos, Indiretos e Transferências;
                      </li>
                      <li>
                        Relatórios de Comparativos de Custos por Centros de
                        Custos, Receitas e resultados;
                      </li>
                      <li>
                        Relatório de análise de custos por Centros de Custos e
                        Ponto de Equilíbrio.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="contabilidade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
                        <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
            <div class="row">
              <div class="col col-1-3">
                <div class="segmento-hospitais center">
                  <span
                    class="modal-container-img modal-hospitais-contabilidade"
                  ></span>
                </div>
              </div>
              <div class="col col-2-3">
                <div class="modulo-descricao">
                  <h3>Sistema de Contabilidade</h3>
                  <div class="modulo-descricao-texto descricao-hospitais">
                    <h5>Principais funcionalidades</h5>
                    <ul>
                      <li>
                        Parâmetros do sistema para definição de critérios de
                        acessos, forma de uso e campos necessários;
                      </li>
                      <li>Atende modelo Multiempresa;</li>
                      <li>
                        Plano de contas em conformidade com o padrão da ANS, e/ou estrutura própria;
                      </li>
                      <li>Uso de Código reduzido;</li>
                      <li>
                        Gerenciamento dos usuários de acordo com seu perfil;
                      </li>
                      <li>Possibilita várias formas de escrituração;</li>
                      <li>Os lançamentos em partidas simples ou dobradas;</li>
                      <li>Possibilita definir lançamentos padrões;</li>
                      <li>
                        Fechamentos automáticos: mensais, trimestrais ou anuais;
                      </li>
                      <li>
                        Geração de gráficos para visualização de resultados;
                      </li>
                      <li>
                        Relatórios contábeis como: balanço, balancete, razão, entre outros.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Cta />

    <WhatsApp />
  </div>
</template>

<script>
import MenuSolucoes from "@/components/MenuSolucoes.vue";
import Cta from "@/components/Cta.vue";
import WhatsApp from "@/components/WhatsApp.vue";
export default {
  name: "Home",
  components: {
    MenuSolucoes,
    Cta,
    WhatsApp,
  },
  methods: {
    Home() {
      window.location.href = "/Home";
    },
  },
};
</script>
